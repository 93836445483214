import React, { MutableRefObject } from 'react';
import { useSensors, useSensor, MouseSensor, TouchSensor, closestCenter, DndContext, DragStartEvent, DragEndEvent, DragOverlay, DragOverEvent, defaultDropAnimationSideEffects } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { FieldInputProps, FormikErrors } from 'formik';
import { Grid } from 'semantic-ui-react';
import { IngredientGroup, Ingredient } from './ingredientGroupHelper';
import IngredientGroupContainer from './IngredientGroupContainer';
import IngredientRow from './IngredientRow';

export type SortableItems = Record<string, string[]>;

interface Props {
  formik: {
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<any>> | Promise<void>,
    values: {
      ingredientGroups: IngredientGroup[];
    },
  },
  ingredientOrderRef: MutableRefObject<SortableItems>,
}

const Ingredients = ({ formik, ingredientOrderRef }: Props) => {
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
  );

  const [items, setItems] = React.useState<SortableItems>(ingredientOrderRef.current);
  ingredientOrderRef.current = items;

  const [activeId, setActiveId] = React.useState<string | null>(null);
  const [clonedItems, setClonedItems] = React.useState<SortableItems | null>(null);

  const findContainerId = (id: string) => {
    if (id in items) {
      return id;
    }
    return Object.keys(items).find((key) => items[key].includes(id));
  };

  function onDragStart({ active }: DragStartEvent) {
    setActiveId(active.id as string);
    setClonedItems(items);
  }

  const onDragOver = ({ active, over }: DragOverEvent) => {
    const overId = over?.id as string;
    const activeId = active.id as string;

    if (!overId || active.id in items) {
      return;
    }

    const overContainerId = findContainerId(overId);
    const activeContainerId = findContainerId(activeId);

    if (!overContainerId || !activeContainerId) {
      return;
    }

    if (activeContainerId !== overContainerId) {
      setItems((prevItems) => {
        const activeItems = prevItems[activeContainerId];
        const overItems = prevItems[overContainerId];
        const overIndex = overItems.indexOf(overId);
        const activeIndex = activeItems.indexOf(activeId);

        let newIndex: number;

        if (overId in prevItems) {
          newIndex = overItems.length + 1;
        } else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top >
            over.rect.top + over.rect.height;

          const modifier = isBelowOverItem ? 1 : 0;

          newIndex =
            overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
        }

        return {
          ...prevItems,
          [activeContainerId]: [
            ...prevItems[activeContainerId].filter((item) => item !== active.id)
          ],
          [overContainerId]: [
            ...prevItems[overContainerId].slice(0, newIndex),
            prevItems[activeContainerId][activeIndex],
            ...prevItems[overContainerId].slice(newIndex, prevItems[overContainerId].length),
          ],
        };
      });
    }
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    console.log(`drag end. \nactive id: ${active.id}, \nover id: ${over?.id}`);
    const overId = over?.id as string;
    const activeId = active.id as string;
    if (!overId) {
      setActiveId(null);
      return;
    }
    const activeContainerId = findContainerId(activeId);
    if (!activeContainerId) {
      setActiveId(null);
      return;
    }
    const overContainerId = findContainerId(overId);
    if (!overContainerId) {
      setActiveId(null);
      return;
    }

    const activeIndex = items[activeContainerId].indexOf(activeId);
    const overIndex = items[overContainerId].indexOf(overId);

    console.log(`activeContainerId: ${activeContainerId}, activeIndex: ${activeIndex},\noverContainerId: ${overContainerId}, overIndex: ${overIndex}`);

    if (activeIndex !== overIndex) {
      setItems((prevItems) => ({
        ...prevItems,
        [overContainerId]: arrayMove(
          prevItems[overContainerId],
          activeIndex,
          overIndex
        ),
      }));
    }
    setActiveId(null);
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  const addIngredient = (groupIdx: number, ingIdx: number) =>
    () => {
      if (ingIdx === formik.values.ingredientGroups[groupIdx].ingredients.length - 1) {
        setItems((prevItems) => ({
          ...prevItems,
          [groupIdx]: prevItems[groupIdx].concat([`${groupIdx}-${ingIdx + 1}`])
        }));
        formik.setFieldValue(`ingredientGroups[${groupIdx}].ingredients`,
          formik.values.ingredientGroups[groupIdx].ingredients.concat(defaultIngredient));

      }
    };

  const addIngredientGroup = (groupIdx: number) =>
    () => {
      if (groupIdx === formik.values.ingredientGroups.length - 1) {
        setItems((prevItems) => ({
          ...prevItems,
          [groupIdx + 1]: [`${groupIdx + 1}-0`]
        }));
        formik.setFieldValue('ingredientGroups',
          formik.values.ingredientGroups.concat(defaultIngredientGroup));
      }
    };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
    >
      {
        formik.values.ingredientGroups.map((_ingredientGroup, groupIdx) => (
          <IngredientGroupContainer
            key={groupIdx}
            groupIdx={groupIdx}
            formik={formik}
            lastIngredientGroup={groupIdx === formik.values.ingredientGroups.length - 1}
            sortableItems={items[groupIdx]}
            addIngredient={addIngredient}
            addIngredientGroup={addIngredientGroup}
            isDragging={itemId => itemId === activeId}
          />
        ))
      }
      <DragOverlay
        adjustScale={true}
        dropAnimation={{
          sideEffects: defaultDropAnimationSideEffects({
            styles: {
              active: {
                opacity: '0.5',
              },
            },
          }),
        }}>
        {activeId ?
          <Grid columns={2}>
            <IngredientRow
              key={activeId}
              itemId={activeId}
              formik={formik}
              addIngredient={addIngredient}
            />
          </Grid> :
          null}
      </DragOverlay>
    </DndContext >
  );
};

export const defaultIngredient: Ingredient = {
  title: '',
  amount: '',
  unit: ''
};

export const defaultIngredientGroup: IngredientGroup = {
  title: '',
  ingredients: [
    defaultIngredient
  ]
};

export default Ingredients;
