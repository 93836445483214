import { NavLink, useLocation } from 'react-router-dom';
import { Container, Dropdown, Icon, Menu } from 'semantic-ui-react';
import { AUTH_TOKEN, LoginProps } from '../App';
import { useIsRecudeNavBar } from '../useMediaQuery';
import ImportRecipeModal from './ImportRecipeModal';

const NavBar = ({ isLoggedIn, setIsLoggedIn }: LoginProps) => {

  const recudeNavBar = useIsRecudeNavBar();
  const location = useLocation();

  const CreateRecipeItem = () => (
    <Menu.Item
      as={NavLink} to='recipe/new'
      name='anlegen'
    />
  );
  const ImportRecipeItem = () => (
    <ImportRecipeModal
      trigger={<Menu.Item name='importieren' />}
    />
  );

  const Item = ({ name, to }: { name: string, to: string; }) => (
    <Menu.Item
      as={NavLink} to={to}
      name={name}
    />
  );

  return (
    <Menu
      style={{ borderRadius: '0' }}
      color='blue'
      size='huge'
      borderless
      inverted>
      <Container>
        {recudeNavBar &&
          <Dropdown item icon='bars'>
            <Dropdown.Menu>
              <Dropdown.Header content='Neues Rezept' />
              <CreateRecipeItem />
              <ImportRecipeItem />
              <Dropdown.Divider />
              <Item name='Suche' to='search' />
              <Item name='Tags' to='tags' />
              <Item name='Planung' to='meal-plan' />
            </Dropdown.Menu>
          </Dropdown>
        }
        <Menu.Item
          as={NavLink} to='/' end
          header
          name='RezeptDB'
        />
        {!recudeNavBar &&
          <>
            <Dropdown item icon={null} text='Neues Rezept'>
              <Dropdown.Menu>
                <CreateRecipeItem />
                <ImportRecipeItem />
              </Dropdown.Menu>
            </Dropdown>
            <Item name='Suche' to='search' />
            <Item name='Tags' to='tags' />
            <Item name='Planung' to='meal-plan' />
          </>
        }
        <Menu.Menu position='right'>
          {isLoggedIn ?
            <Dropdown item icon='user'>
              <Dropdown.Menu>
                <Menu.Item
                  position='right'
                  name='Logout'
                  title='Logout'
                  onClick={() => {
                    setIsLoggedIn(false);
                    localStorage.removeItem(AUTH_TOKEN);
                  }}
                >
                </Menu.Item>
              </Dropdown.Menu>
            </Dropdown>
            :
            <Menu.Item
              as={NavLink} to='login' state={{ from: location }}
              name='Login'
              title='Login'
            >
              <Icon name='user outline' />
            </Menu.Item>
          }

        </Menu.Menu>
      </Container >
    </Menu >
  );
};

export default NavBar;
