import { useAddMealMutation, MealDataFragmentDoc, PlannedMealsDataFragment } from './generated/graphql';

export function useAddMealMutationWithCacheUpdate() {
  return useAddMealMutation({
    onError: (error) => {
      console.log(error);
    },
    update: (cache, { data }) => {
      if (!data?.addMeal) {
        return;
      }
      cache.modify({
        fields: {
          mealPlan(existingMealPlan) {
            if (!existingMealPlan) {
              return;
            }
            const newMealRef = cache.writeFragment({
              data: data.addMeal.meals[data.addMeal.meals.length - 1],
              fragment: MealDataFragmentDoc,
              fragmentName: 'MealData'
            });
            if (data.addMeal.dateString) {
              const plannedMealsToUpdate = existingMealPlan.plannedMeals
                .find((meals: PlannedMealsDataFragment) => meals.dateString === data.addMeal.dateString) || {
                dateString: data.addMeal.dateString,
                meals: [],
              };
              return {
                __typename: 'MealPlan',
                plannedMeals: [
                  ...existingMealPlan.plannedMeals
                    .filter((meals: PlannedMealsDataFragment) => meals.dateString !== data.addMeal.dateString),
                  {
                    __typename: 'PlannedMeals',
                    dateString: plannedMealsToUpdate.dateString,
                    meals: [...plannedMealsToUpdate.meals, newMealRef]
                  }
                ],
                reservedMeals: existingMealPlan.reservedMeals,
              };
            } else {
              return {
                __typename: 'MealPlan',
                plannedMeals: existingMealPlan.plannedMeals,
                reservedMeals: [...existingMealPlan.reservedMeals, newMealRef],
              };
            }
          }
        }
      });
    }
  });
}
