import { useLocation } from 'react-router-dom';
import AddRecipe from '../components/AddRecipe';
import ImportRecipeFromSource from '../components/recipeImport/ImportRecipeFromSource';
import ImportRecipeFromUrl from '../components/recipeImport/ImportRecipeFromUrl';
import { defaultIngredient, defaultIngredientGroup } from '../components/recipeInputForm/Ingredients';

interface LocationState {
  importUrl?: string,
  source?: string,
}

const NewRecipe = () => {

  document.title = 'Neues Rezept - RezeptDB';

  const locationState = useLocation().state as LocationState;

  if (locationState && locationState.importUrl) {
    return (
      <ImportRecipeFromUrl importUrl={locationState.importUrl} />
    );
  }

  if (locationState && locationState.source) {
    return (
      <ImportRecipeFromSource source={locationState.source} />
    );
  }

  // no import -> manually create recipe
  return (
    <AddRecipe
      recipe={{
        title: '',
        yields: 2,
        yieldUnit: 'Portionen',
        prepTime: undefined,
        cookTime: undefined,
        ingredientGroups: [{
          ingredients: [
            defaultIngredient
          ]
        },
          defaultIngredientGroup
        ],
        instructions: '',
        notes: '',
        tags: [],
      }}
    />
  );
};

export default NewRecipe;
