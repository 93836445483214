import React from 'react';

function useMediaQuery(query: string) {
  const [matches, setMatches] = React.useState(false);

  React.useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    if (mediaQueryList.matches !== matches) {
      setMatches(mediaQueryList.matches);
    }

    mediaQueryList.addEventListener('change', () => setMatches(mediaQueryList.matches));
    return () => mediaQueryList.removeEventListener('change', () => setMatches(mediaQueryList.matches));
  }, [matches, query]);

  return matches;
}

export const useIsRecudeNavBar = () => useMediaQuery('(max-width: 600px)');

export const useIsComputer = () => useMediaQuery('(min-width: 992px)');
