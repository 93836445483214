import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import './App.css';
import NavBar from './components/NavBar';
import NewRecipe from './pages/NewRecipe';
import Home from './pages/Home';
import Search from './pages/Search';
import Login from './pages/Login';
import Recipe from './pages/Recipe';
import EditRecipe from './pages/EditRecipe';
import TagsOverview from './pages/TagsOverview';
import RecipesByTag from './pages/RecipesByTag';
import MealPlan from './pages/MealPlan';

export const AUTH_TOKEN = 'rezeptdb';

export interface LoginProps {
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void,
}

const App = () => {

  const [isLoggedIn, setIsLoggedIn] = React.useState(!!localStorage.getItem(AUTH_TOKEN));
  const location = useLocation();

  return (
    <div className='App'>
      <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <Container>
        <Routes>
          <Route
            path='/'
            element={<Home />}
          />
          <Route
            path='login'
            element={<Login isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />}
          />
          <Route
            path='search'
            element={<Search />}
          />
          <Route
            path='tags'
            element={<TagsOverview isLoggedIn={isLoggedIn} />}
          />
          <Route
            path='tag/:title'
            element={<RecipesByTag />}
          />
          <Route
            path='meal-plan'
            element={<MealPlan />}
          />
          <Route
            path='recipe/new'
            element={
              isLoggedIn ?
                <NewRecipe /> :
                <Navigate
                  to='/login'
                  state={{ from: location }}
                />
            }
          />
          <Route
            path='recipe/:id'
            element={<Recipe isLoggedIn={isLoggedIn} />}
          />
          <Route
            path='recipe/:id/edit'
            element={
              isLoggedIn ?
                <EditRecipe /> :
                <Navigate
                  to='/login'
                  state={{ from: location }}
                />
            }
          />
        </Routes>
      </Container>
    </div>
  );
};

export default App;
