import React from 'react';
import { Grid } from 'semantic-ui-react';
import { IngredientDataFragment } from '../../generated/graphql';
import { useMarkableColor } from '../../useMarkableColor';
import ChangeYieldsModal from './ChangeYieldsModal';

interface Props {
  ingredient: IngredientDataFragment,
  yieldsFactor: number,
  setYieldsFactor: (factor: number) => void,
}

const Ingredient = ({ ingredient, yieldsFactor, setYieldsFactor }: Props) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [isMarked, setIsMarked] = React.useState(false);

  const currentAmount = ingredient.amount && Math.round(((ingredient.amount * yieldsFactor) + Number.EPSILON) * 100) / 100;

  return (
    <Grid.Row
      style={{ paddingBottom: '0.5rem', paddingTop: '0.5rem', backgroundColor: useMarkableColor(isMarked) }}
      onClick={() => setIsMarked(!isMarked)}
    >
      {!!ingredient.amount && !!currentAmount ?
        <IngredientAmountWithModal
          amount={ingredient.amount}
          currentAmount={currentAmount}
          unit={ingredient.unit || undefined}
          title={ingredient.title}
          openModal={openModal}
          setOpenModal={setOpenModal}
          setYieldsFactor={setYieldsFactor}
        /> :
        <>
          <Grid.Column mobile={3} tablet={3} computer={2} style={{ paddingRight: '0.5rem' }} textAlign='right'>
            {currentAmount}
          </Grid.Column>
          <Grid.Column width={2} style={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
            {ingredient.unit}
          </Grid.Column>
        </>
      }
      <Grid.Column mobile={11} tablet={11} computer={12} style={{ paddingLeft: '0.5rem' }}>
        {ingredient.title}
      </Grid.Column>
    </Grid.Row>
  );
};

interface IngredientAmountWithModalProps {
  amount: number,
  currentAmount: number,
  unit?: string,
  title: string,
  openModal: boolean,
  setOpenModal: (openModal: boolean) => void,
  setYieldsFactor: (factor: number) => void,
}

const IngredientAmountWithModal = ({ amount, currentAmount, unit, title, openModal, setOpenModal, setYieldsFactor }: IngredientAmountWithModalProps) => {

  const openOnClick = (e: Event) => {
    e.stopPropagation();
    setOpenModal(true);
  };

  return (<>
    <Grid.Column mobile={3} tablet={3} computer={2}
      style={{ paddingRight: '0.5rem', cursor: 'pointer' }}
      textAlign='right'
      onClick={openOnClick}
    >
      {currentAmount}
    </Grid.Column>
    <Grid.Column width={2}
      style={{ paddingRight: '0.5rem', paddingLeft: '0.5rem', cursor: 'pointer' }}
      onClick={openOnClick}
    >
      {unit}
    </Grid.Column>
    <ChangeYieldsModal
      amount={amount}
      currentAmount={currentAmount}
      unit={unit}
      title={title}
      openModal={openModal}
      setOpenModal={setOpenModal}
      setYieldsFactor={setYieldsFactor}
    />
  </>);
};

export default Ingredient;
