import { Message } from 'semantic-ui-react';
import { useImportRecipeQuery } from '../../generated/graphql';
import ImportRecipe from './ImportRecipe';

type Props = {
  importUrl: string;
};

const ImportRecipeFromUrl = ({ importUrl }: Props) => {

  const { error, called, data } = useImportRecipeQuery({
    onError: (error) => {
      console.log(error.message);
      return (<Message error
        header='Da ist etwas schiefgelaufen!'
        content={error.message} />);
    },
    variables: {
      url: importUrl
    }
  });
  return (
    <ImportRecipe called={called} error={error} data={data?.importRecipe} />
  );
};


export default ImportRecipeFromUrl;
