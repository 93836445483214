import React, { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import ReactMarkdown from 'react-markdown';
import { List } from 'semantic-ui-react';
import { useMarkableColor } from '../../useMarkableColor';

interface Props {
  text: string,
  mark: boolean,
  unmark: boolean,
  setMarkedItemsCount: Dispatch<SetStateAction<number>>,
}

const MarkableListItem = ({ text, mark, unmark, setMarkedItemsCount }: Props) => {
  const [isMarked, setIsMarked] = React.useState(mark);

  React.useLayoutEffect(() => {
    if (mark) {
      setIsMarked(true);
    }
  }, [mark]);

  React.useLayoutEffect(() => {
    if (unmark) {
      setIsMarked(false);
    }
  }, [unmark]);

  const toggleIsMarked = (event: SyntheticEvent) => {
    event.stopPropagation();
    if (isMarked) {
      setIsMarked(false);
      setMarkedItemsCount(prevCount => prevCount - 1);
    } else {
      setIsMarked(true);
      setMarkedItemsCount(prevCount => prevCount + 1);
    }
  };

  return (
    <List.Item
      className='markable-item'
      style={{ backgroundColor: useMarkableColor(isMarked) }}
      onClick={toggleIsMarked}
    >
      <List.Icon name={isMarked ? 'check circle outline' : 'circle outline'} />
      <List.Content>
        <ReactMarkdown>{text}</ReactMarkdown>
      </List.Content>
    </List.Item>
  );

};

export default MarkableListItem;
