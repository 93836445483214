import React from 'react';
import { FieldInputProps, FormikErrors } from 'formik';
import { Link } from 'react-router-dom';
import { Form, Message, Grid, Header, Segment, Button } from 'semantic-ui-react';
import { IngredientGroup } from './ingredientGroupHelper';
import Ingredients, { defaultIngredient, SortableItems } from './Ingredients';
import RecipeHead from './RecipeHead';
import TextEditor from './TextEditor';

interface Props {
  formik: {
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<any>> | Promise<void>;
    values: {
      ingredientGroups: IngredientGroup[],
      instructions?: string,
      notes?: string,
      tags: string[],
    };
  },
  imageUrl?: string,
  error?: Error;
  redirectUrlOnCancle: string,
}

const RecipeInputForm = ({ formik, imageUrl, error, redirectUrlOnCancle }: Props) => {

  const ingredientOrder = React.useRef<SortableItems>(
    Object.fromEntries(
      formik.values.ingredientGroups
        .map((group, groupIdx) => ([
          groupIdx,
          group.ingredients.map((_ing, idx) => `${groupIdx}-${idx}`)
        ]))
    ));
  const handleSubmit = (e?: React.FormEvent<HTMLFormElement> | undefined) => {
    formik.values.ingredientGroups = getSortedIngredients(formik.values.ingredientGroups, ingredientOrder.current);
    formik.handleSubmit(e);
  };

  const setFormikValue = (fieldName: string) => (value: any) =>
    formik.setFieldValue(fieldName, value);

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        error={!!error}
      >
        <Message error
          header='Da ist etwas schiefgelaufen!'
          content={error?.message} />

        <Grid columns='2' stackable>
          <Grid.Row>
            <RecipeHead
              title={formik.getFieldProps('title')}
              yields={formik.getFieldProps('yields')}
              yieldUnit={formik.getFieldProps('yieldUnit')}
              prepTime={formik.getFieldProps('prepTime')}
              cookTime={formik.getFieldProps('cookTime')}
              absoluteImageUrl={!!imageUrl ? process.env.REACT_APP_REZEPTDB_API_BASE_URL + imageUrl : ''}
              setImage={setFormikValue('image')}
              tags={formik.values.tags}
              setTags={setFormikValue('tags')}
            />
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Ingredients
                formik={formik}
                ingredientOrderRef={ingredientOrder}
              />
            </Grid.Column>

            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Header as='h4' attached='top' block>
                Zubereitung
              </Header>
              <Segment attached='bottom'>
                <TextEditor
                  initialValue={formik.values.instructions || ''}
                  setValue={setFormikValue('instructions')}
                />
              </Segment>

              <Header as='h4' attached='top' block>
                Notizen
              </Header>
              <Segment attached='bottom'>
                <TextEditor
                  initialValue={formik.values.notes || ''}
                  setValue={setFormikValue('notes')}
                />
              </Segment>
            </Grid.Column>

          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column floated='right'>
              <Button
                primary
                type='submit'
                floated='right'
                content='Speichern'
              />
              <Button
                as={Link} to={redirectUrlOnCancle}
                negative
                type='button'
                floated='right'
                content='Abbrechen'
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </>
  );
};

const getSortedIngredients = (ingredientGroups: IngredientGroup[], ingredientOrder: SortableItems): IngredientGroup[] => {
  const sortedIngredients = Object.entries(ingredientOrder)
    .map(([key, items]) => ({
      title: ingredientGroups[Number(key)].title,
      ingredients: items
        .map((item) => item.split('-'))
        .map(([groupIdx, idx]) => ingredientGroups[Number(groupIdx)].ingredients[Number(idx)])
    }));

  sortedIngredients.forEach((group, idx) => {
    while (group.ingredients.length < ingredientGroups[idx].ingredients.length) {
      group.ingredients.push(defaultIngredient);
    }
  });

  return sortedIngredients;
};


export default RecipeInputForm;
