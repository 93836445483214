import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Modal, Form, Button } from 'semantic-ui-react';

interface Props {
  trigger: ReactElement,
}

const ImportRecipeModal = ({ trigger }: Props) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      url: '',
      source: '',
    },
    onSubmit: (values) => navigate(`/recipe/new`, {
      state: {
        importUrl: values.url,
        source: values.source
      }
    })
  });

  const handleCancle = () => {
    formik.resetForm();
    setOpen(false);
  };

  return (
    <Modal as={Form}
      onSubmit={formik.handleSubmit}
      onClick={(e: Event) => e.stopPropagation()}
      trigger={trigger}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleCancle}
    >
      <Modal.Header>Rezept importieren</Modal.Header>
      <Modal.Content>
        <Form.Input
          {...formik.getFieldProps('url')}
          fluid
          autoFocus
          label='Link zum Rezept'
        />
        oder
        <Form.TextArea
          {...formik.getFieldProps('source')}
          label='HTML-Quellcode der Rezeptseite'
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          type='button'
          content='Abbrechen'
          onClick={handleCancle}
        />
        <Button
          primary
          type='submit'
          content='Importieren'
        />
      </Modal.Actions>
    </Modal>
  );
};


export default ImportRecipeModal;
