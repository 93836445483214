import React from 'react';
import { Link, useNavigationType } from 'react-router-dom';
import { List } from 'semantic-ui-react';
import { RecipesQuery } from '../generated/graphql';
import RecipeThumbnail from './RecipeThumbnail';


interface Props {
  data: RecipesQuery,
  filterQuery?: string,
  scrollOffsetKey?: string,
  onRecipeClick?: () => void,
}

const RecipeList = ({ data, filterQuery, scrollOffsetKey, onRecipeClick }: Props) => {

  const navType = useNavigationType();
  React.useEffect(() => {
    if (scrollOffsetKey && navType === 'POP') {
      const scrollPosition = Number(sessionStorage.getItem(scrollOffsetKey));
      sessionStorage.removeItem(scrollOffsetKey);
      window.scrollTo(0, scrollPosition);
    }
  });

  return (
    <List divided selection>
      {!!data.recipes &&
        [...data.recipes]
          .sort((r1, r2) => r1.title.localeCompare(r2.title))
          .filter(recipe => !filterQuery || recipe.title.toLocaleLowerCase().includes(filterQuery))
          .map((recipe, i) =>
            !!recipe && (
              <List.Item
                as={Link} to={`/recipe/${recipe.id}`}
                onClick={onRecipeClick}
                style={{ display: 'flex', alignItems: 'center' }}
                key={i}
              >
                <RecipeThumbnail recipe={recipe} />
                <List.Content verticalAlign='middle'>
                  <List.Header>{recipe.title}</List.Header>
                  {recipe.tagList && recipe.tagList.length > 0 && (
                    <List.Description>
                      {recipe.tagList.join(', ')}
                    </List.Description>
                  )}
                </List.Content>
              </List.Item>
            ),
          )}
    </List>
  );
};



export default RecipeList;
