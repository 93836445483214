import { useFormik } from 'formik';
import { Navigate, useParams } from 'react-router-dom';
import { Dimmer, Loader, Message } from 'semantic-ui-react';
import { Ingredient, IngredientGroup, mapToIngredientInputs } from '../components/recipeInputForm/ingredientGroupHelper';
import { defaultIngredient, defaultIngredientGroup } from '../components/recipeInputForm/Ingredients';
import RecipeInputForm from '../components/recipeInputForm/RecipeInputForm';
import { Ingredient as InputIngredient, RecipeDataFragment, TagsDocument, useRecipeQuery, useUpdateRecipeMutation } from '../generated/graphql';

const EditRecipe = () => {
  const params = useParams();

  const { data, error, loading } = useRecipeQuery({
    variables: {
      id: Number(params.id || ''),
    }
  });

  if (loading) {
    return (
      <Dimmer inverted active>
        <Loader />
      </Dimmer>
    );
  }

  if (error || !data) {
    return (<Message error
      header='Da ist etwas schiefgelaufen!'
      content={error?.message} />);
  }

  document.title = `${data.recipe.title} - RezeptDB`;

  return (
    <EditForm recipe={data.recipe} />
  );
};

interface EditFormProps {
  recipe: RecipeDataFragment;
}

const EditForm = ({ recipe }: EditFormProps) => {

  const [updateRecipe, { error, called, loading }] = useUpdateRecipeMutation({
    onError: (error) => {
      console.log(error);
    },
    refetchQueries: [{ query: TagsDocument }],
  });

  const formik = useFormik({
    initialValues: {
      title: recipe.title,
      yields: recipe.yields,
      yieldUnit: recipe.yieldUnit,
      prepTime: !!recipe.prepTime ? Number(recipe.prepTime) / 60 : '',
      cookTime: !!recipe.cookTime ? Number(recipe.cookTime) / 60 : '',
      ingredientGroups: createInitialIngredients(recipe),
      instructions: recipe.instructions || '',
      notes: recipe.notes || '',
      image: '',
      tags: recipe.tagList || [],
    },
    onSubmit: (values) => {
      updateRecipe({
        variables: {
          input: {
            id: recipe.id,
            lastUpdatedAt: recipe.updatedAt,
            recipeInput: {
              title: values.title || '',
              yields: values.yields || undefined,
              yieldUnit: values.yieldUnit || undefined,
              prepTime: !!values.prepTime ? Number(values.prepTime) * 60 : undefined,
              cookTime: !!values.cookTime ? Number(values.cookTime) * 60 : undefined,
              ingredientInputs: mapToIngredientInputs(values.ingredientGroups),
              instructions: values.instructions || undefined,
              notes: values.notes || undefined,
              imageInput: values.image || undefined,
              tagList: values.tags || [],
            }
          }
        }
      });
    }
  });

  if (loading) {
    return (
      <Dimmer inverted active>
        <Loader />
      </Dimmer>
    );
  }

  if (!!called && !error) {
    return < Navigate
      to={`/recipe/${recipe.id}`}
      state={{ imageHash: Date.now() }}
    />;
  }

  return <RecipeInputForm
    formik={formik}
    imageUrl={recipe.image?.url}
    error={error}
    redirectUrlOnCancle={`/recipe/${recipe.id}`}
  />;
};

const createInitialIngredients = (recipe: RecipeDataFragment): IngredientGroup[] => {

  const toIngredientsWithDefault = (ingredients: InputIngredient[]): Ingredient[] => {

    const ings: Ingredient[] = ingredients.map(ingredient => ({
      id: ingredient.id,
      title: ingredient.title,
      unit: ingredient.unit || '',
      amount: ingredient.amount?.toString() || '',
    }));
    ings.push(defaultIngredient);
    return ings;

  };

  if (!!recipe.groupedIngredients && recipe.groupedIngredients.length > 0) {
    const groupedIngredients: IngredientGroup[] = recipe.groupedIngredients
      .map(ingGroup => ({
        title: ingGroup.title || undefined,
        ingredients: toIngredientsWithDefault(ingGroup.ingredients),
      }));
    groupedIngredients.push(defaultIngredientGroup);

    if (!!groupedIngredients[0].title) {
      // first ingredient group is not default group => add default group
      return [defaultIngredientGroup].concat(groupedIngredients);
    }
    return groupedIngredients;
  }

  return [{
    ingredients: [
      defaultIngredient
    ]
  },
    defaultIngredientGroup
  ];
};

export default EditRecipe;
