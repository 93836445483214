import { useFormik } from 'formik';
import { Dropdown, Form, Message } from 'semantic-ui-react';
import { useAddMealMutationWithCacheUpdate } from '../../useAddMealMutationWithCacheUpdate';
import { DateTime } from 'luxon';
import MealPlanDatepicker from '../MealPlanDatepicker';

const AddNoteForm = () => {

  const [addMealWithNote, { data, called, error }] = useAddMealMutationWithCacheUpdate();

  const formik = useFormik({
    initialValues: {
      note: '',
      dateString: '',
    },
    onSubmit: (values, { resetForm }) => {
      addMealWithNote({
        variables: {
          input: {
            note: values.note,
            dateString: values.dateString || undefined
          }
        }
      });
      resetForm();
    }
  });

  return (
    <>
      {!!called && !!data &&
        <Message success
          content={data.addMeal.dateString
            ? `Die Notiz wurde erfolgreich für den ${DateTime.fromISO(data.addMeal.dateString).setLocale('de-DE').toLocaleString(DateTime.DATE_FULL)} vermerkt`
            : 'Die Notiz wurde erfolgreich vorgemerkt.'}>
        </Message>}
      {!!called && !!error &&
        <Message error
          header='Da ist etwas schiefgelaufen!'
          content={error?.message}
        />}
      <Form>
        <Form.Input
          {...formik.getFieldProps('note')}
          placeholder='Notiz'
          action
          fluid
        >
          <input />
          <Dropdown
            button
            compact
            className='icon'
            icon='calendar plus outline'
            title='einplanen'
            direction='left'
          >
            <Dropdown.Menu>
              <Dropdown.Header
                content='einplanen'
              />
              <Dropdown.Item
                text='vormerken'
                onClick={() => formik.handleSubmit()}
              />
              <Dropdown.Item
                onClick={(e) => e.stopPropagation()}
              >
                <MealPlanDatepicker
                  handleSelectedDate={(date: DateTime) => {
                    formik.setFieldValue('dateString', date.toISODate());
                    return formik.handleSubmit();
                  }}
                />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Form.Input>
      </Form>
    </>
  );
};

export default AddNoteForm;
