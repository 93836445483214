import { Grid, Header, Segment } from 'semantic-ui-react';
import { IngredientGroupDataFragment } from '../../generated/graphql';
import Ingredient from './Ingredient';

interface Props {
  count: number,
  ingredientGroup: IngredientGroupDataFragment,
  lastIngredientGroup: boolean,
  yieldsFactor: number,
  setYieldsFactor: (factor: number) => void,
}

const IngredientGroup = ({ count, ingredientGroup, lastIngredientGroup, yieldsFactor, setYieldsFactor }: Props) => {
  return (
    <>
      <Header as='h4' attached={count === 0 ? 'top' : true} block>
        {!!ingredientGroup.title ? ingredientGroup.title : 'Zutaten'}
      </Header>
      <Segment attached={lastIngredientGroup ? 'bottom' : true} style={{ overflow: 'hidden' }}>
        {!!ingredientGroup.ingredients &&
          <Grid>
            {ingredientGroup.ingredients
              .map((ingredient, i) =>
                <Ingredient key={i}
                  ingredient={ingredient}
                  yieldsFactor={yieldsFactor}
                  setYieldsFactor={setYieldsFactor}
                />
              )}
          </Grid>}
      </Segment>
    </>
  );
};

export default IngredientGroup;
