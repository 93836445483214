import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { Message, Modal } from 'semantic-ui-react';
import { useRemoveRecipeMutation } from '../../generated/graphql';

interface Props {
  recipeId: number,
  recipeTitle: string,
  recipeUpdatedAt: Date,
  trigger: ReactElement;
}

const DeleteRecipeModal = ({ recipeId, recipeTitle, recipeUpdatedAt, trigger }: Props) => {
  const [removeRecipe, { called, loading, error }] = useRemoveRecipeMutation({
    onError: (error) => {
      console.log(error);
    }
  });

  const deleteRecipe = () => {
    removeRecipe({
      variables: {
        input: {
          id: recipeId,
          lastUpdatedAt: recipeUpdatedAt
        }
      }
    });
  };

  if ((!!called && !loading)) {
    return <Navigate to='/' />;
  }

  if (!!called && !!error) {
    return (<Message error
      header='Da ist etwas schiefgelaufen!'
      content={error?.message} />);
  }

  return (<Modal
    trigger={trigger}
    header='Rezept löschen'
    content={`Soll das Rezept "${recipeTitle}" endgültg gelöscht werden?`}
    actions={[
      'Abbrechen',
      { key: 'delete', content: 'Löschen', negative: true, onClick: deleteRecipe }
    ]}
  />);
};

export default DeleteRecipeModal;
