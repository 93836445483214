import { DateTime } from 'luxon';
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Header, Button, Divider, Image, Label, Message } from 'semantic-ui-react';
import { RecipeDataFragment } from '../../generated/graphql';
import { toTimeString } from '../../timeUtil';
import { useAddMealMutationWithCacheUpdate } from '../../useAddMealMutationWithCacheUpdate';
import DeleteRecipeModal from './DeleteRecipeModal';
import IngredientGroup from './IngredientGroup';
import RecipeText from './RecipeText';
import YieldsButtons from './YieldsButtons';
import UpdateMealButton from '../UpdateMealButton';
import { reservedListId } from '../../constants';

interface RecipeViewProps {
  recipe: RecipeDataFragment,
  imageHash: string,
  isLoggedIn: boolean,
}

const RecipeView = ({ recipe, imageHash, isLoggedIn }: RecipeViewProps) => {

  const [yieldsFactor, setYieldsFactor] = React.useState(1);
  const [addMeal, { data, called, error }] = useAddMealMutationWithCacheUpdate();
  return (
    <>
      <Container>
        {!!called && !!data &&
          <Message success>
            <p>
              {data.addMeal.dateString
                ? `Das Rezept wurde erfolgreich für den ${DateTime.fromISO(data.addMeal.dateString).setLocale('de-DE').toLocaleString(DateTime.DATE_FULL)} eingeplant`
                : 'Das Rezept wurde erfolgreich vorgemerkt.'}
            </p>
            <Link to='/meal-plan'>Zur Planung</Link>
          </Message>}
        {!!called && !!error &&
          <Message error
            header='Da ist etwas schiefgelaufen!'
            content={error?.message}
          />}
        <Grid stackable columns={2}>
          <Grid.Column>
            <Grid columns={2}>
              <Grid.Column width={14} verticalAlign='middle'>
                <Header>
                  {recipe.title}
                </Header>
              </Grid.Column>
              {isLoggedIn &&
                <Grid.Column width={2} verticalAlign='top' textAlign='right'>
                  <UpdateMealButton
                    updateMeal={(toListId: DateTime | typeof reservedListId) => {
                      addMeal({
                        variables: {
                          input: {
                            recipeId: recipe.id,
                            dateString: toListId === reservedListId
                              ? undefined
                              : toListId.toISODate()
                          }
                        }
                      });
                    }}
                    icon='calendar plus outline'
                    title='einplanen'
                    showReserveMealOption={true}
                  />
                </Grid.Column>}
            </Grid>
            {recipe.tagList?.map((tag, i) =>
              <Label
                as={Link} to={`/tag/${tag}`}
                key={i}
                color='blue'
                content={tag} />)}
            <p>
              {!!recipe.prepTime && (<span>Vorbereitungszeit: {toTimeString(recipe.prepTime)}</span>)}
              {!!recipe.prepTime && !!recipe.cookTime && (<br />)}
              {!!recipe.cookTime && (<span>Zubereitungszeit: {toTimeString(recipe.cookTime)}</span>)}
            </p>
            <YieldsButtons
              originalYields={recipe.yields}
              yieldUnit={recipe.yieldUnit}
              yieldsFactor={yieldsFactor}
              setYieldsFactor={setYieldsFactor}
            />
            <div>
              <sub>
                Zuletzt aktualisiert am {DateTime.fromISO(recipe.updatedAt).setLocale('de-DE').toLocaleString({ ...DateTime.DATETIME_MED, month: 'long', weekday: 'long' })} Uhr
              </sub>
            </div>
          </Grid.Column>
          {!!recipe.image?.url &&
            <Grid.Column>
              <Image
                src={`${process.env.REACT_APP_REZEPTDB_API_BASE_URL}${recipe.image.url}?${imageHash}`}
                alt={recipe.title}
                floated='right'
                rounded
              />
            </Grid.Column>
          }
        </Grid>
      </Container>
      <Divider />
      <Container>
        <Grid stackable columns={2}>
          <Grid.Column>
            {!!recipe.groupedIngredients &&
              recipe.groupedIngredients.map((ingredientGroup, i) =>
                <IngredientGroup
                  key={i}
                  count={i}
                  ingredientGroup={ingredientGroup}
                  lastIngredientGroup={!!recipe.groupedIngredients && i === recipe.groupedIngredients.length - 1}
                  yieldsFactor={yieldsFactor}
                  setYieldsFactor={setYieldsFactor}
                />
              )}
          </Grid.Column>
          {(!!recipe.instructions || !!recipe.notes) &&
            <Grid.Column>
              {!!recipe.instructions &&
                <RecipeText heading='Zubereitung' text={recipe.instructions} isMarkable={true} />}
              {!!recipe.notes &&
                <RecipeText heading='Notizen' text={recipe.notes} />}
            </Grid.Column>
          }

          {isLoggedIn && (
            <Grid.Row columns={1}>
              <Grid.Column>
                <Button
                  as={Link} to='edit'
                  primary
                  floated='right'
                  content='Rezept bearbeiten'
                />
                <DeleteRecipeModal
                  recipeId={recipe.id}
                  recipeTitle={recipe.title}
                  recipeUpdatedAt={recipe.updatedAt}
                  trigger={<Button negative floated='right' content='Rezept löschen' />}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default RecipeView;
