import { DateTime } from 'luxon';
import { amountOfFutureMealPlanDays, reservedListId } from '../../constants';
import { PlannedMealsDataFragment, MealDataFragment } from '../../generated/graphql';
import MealList, { Meal } from './MealList';

type Props = {
  plannedMeals: PlannedMealsDataFragment[],
  moveMeal: (
    fromListId: DateTime | typeof reservedListId,
    toListId: DateTime | typeof reservedListId,
    movedMeal: Meal
  ) => void,
};

const PlanningView = ({ plannedMeals, moveMeal }: Props) => {
  const recipesFor = (dateString: string | null): MealDataFragment[] => plannedMeals
    .filter(meals => meals.dateString === dateString)
    .flatMap(meals => meals.meals);

  return (
    <>{
      Array.from(Array(amountOfFutureMealPlanDays).keys())
        .map((idx) => {
          const day = DateTime.now().plus({ days: idx }).setLocale('de-DE');
          const dayString = day.toISODate();
          if (!dayString) {
            return undefined;
          }
          const meals = recipesFor(dayString);
          if (idx > 2 && (!meals || meals.length === 0)) {
            return undefined;
          }
          return (
            <MealList
              key={idx}
              header={`${day.weekdayLong}, ${day.toLocaleString()}`}
              listId={dayString}
              meals={meals}
              moveMeal={(
                toListId: DateTime | typeof reservedListId,
                movedMeal: Meal,
              ) => moveMeal(day, toListId, movedMeal)}
            />
          );
        })
    }
    </>
  );
};

export default PlanningView;
