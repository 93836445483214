import { useFormik } from 'formik';
import { Location, Navigate, useLocation } from 'react-router-dom';
import { Container, Dimmer, Form, Loader, Message, Segment } from 'semantic-ui-react';
import { AUTH_TOKEN, LoginProps } from '../App';
import { useLoginMutation } from '../generated/graphql';

interface LocationState {
  from: Location,
}

const Login = ({ isLoggedIn, setIsLoggedIn }: LoginProps) => {

  document.title = 'Login - RezeptDB';

  const locationState = useLocation().state as LocationState;
  const { from } = locationState || { from: { pathname: '/' } };

  const [login, { error, loading }] = useLoginMutation({
    onCompleted: ({ login }) => {
      localStorage.setItem(AUTH_TOKEN, login.token);
      setIsLoggedIn(!!login.token);
    },
    onError: () => {
      localStorage.removeItem(AUTH_TOKEN);
      setIsLoggedIn(false);
    }
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: (values, { setFieldValue }) => {
      login({
        variables: {
          name: values.username,
          password: values.password,
        }
      });
      setFieldValue('password', '');
    }
  });

  if (isLoggedIn) {
    return <Navigate to={from} />;
  }

  if (loading) {
    return (
      <Dimmer inverted active>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <>
      <Container text>
        <Form
          size='large'
          onSubmit={formik.handleSubmit}
          error={!!error}
        >
          <Segment>
            <Form.Input
              {...formik.getFieldProps('username')}
              required
              fluid
              autoFocus
              icon='user'
              iconPosition='left'
              placeholder='Benutzername'
            />
            <Form.Input
              {...formik.getFieldProps('password')}
              required
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Passwort'
              type='password'
            />
            <Form.Button primary fluid type='submit' size='large' content='Login' />
          </Segment>
          <Message error>
            {
              !!error?.message.includes('Invalid name and/or password provided.') ?
                'Benutzername und/oder Passwort sind ungültig.' :
                'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
            }
          </Message>
        </Form>
      </Container>
    </>
  );
};

export default Login;
