import { Dimmer, Loader, Divider, Message } from 'semantic-ui-react';
import { useRecipesQuery, OrderByField, OrderByDirection } from '../generated/graphql';
import RecipeList from './RecipeList';

interface Props {
  ingredients: string[],
  tags: string[],
}

const SearchResult = ({ ingredients, tags }: Props) => {
  const { loading, data } = useRecipesQuery({
    variables: {
      orderBy: {
        field: OrderByField.Title,
        direction: OrderByDirection.Asc
      },
      filter: {
        ingredients,
        tags,
      }
    }
  });

  return (
    <>
      {loading ?
        <Dimmer inverted active>
          <Loader />
        </Dimmer> :
        (!!data && data.recipes.length > 0 ?
          <>
            <Divider />
            <RecipeList data={data} />
          </> :
          <Message warning>
            Leider wurden keine Rezepte gefunden.
          </Message>
        )
      }
    </>
  );
};

export default SearchResult;
