import { useImportRecipeFromSourceQuery } from '../../generated/graphql';
import ImportRecipe from './ImportRecipe';

type Props = {
  source: string;
};

const ImportRecipeFromSource = ({ source }: Props) => {

  const { error, called, data } = useImportRecipeFromSourceQuery({
    onError: (error) => {
      console.log(error);
    },
    variables: {
      source: encodeURIComponent(source)
    }
  });
  return (
    <ImportRecipe called={called} error={error} data={data?.importRecipeFromSource} />
  );
};


export default ImportRecipeFromSource;
