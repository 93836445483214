
import { Form } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';

interface Props {
  initialValue: string,
  setValue: (value: string) => void,
}

const TextEditor = ({ initialValue, setValue }: Props) => {
  return (
    <Form.Field
      as={TextareaAutosize}
      value={initialValue}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
    />
  );
};

export default TextEditor;
