import React from 'react';
import { Button, Form, Grid, Modal } from 'semantic-ui-react';

interface Props {
  amount: number,
  currentAmount: number,
  unit?: string,
  title: string,
  openModal: boolean,
  setOpenModal: (openModal: boolean) => void,
  setYieldsFactor: (factor: number) => void,
}

const ChangeYieldsModal = ({ amount, currentAmount, unit, title, openModal, setOpenModal, setYieldsFactor }: Props) => {

  const [newAmount, setNewAmount] = React.useState(currentAmount);
  React.useEffect(() => {
    setNewAmount(currentAmount);
  }, [currentAmount]);

  const handleSubmit = (e: Event) => {
    e.preventDefault();
    setYieldsFactor(newAmount / amount);
    setOpenModal(false);
  };

  const handleCancle = () => {
    setNewAmount(currentAmount);
    setOpenModal(false);
  };

  return (
    <Modal as={Form}
      onSubmit={(e: Event) => handleSubmit(e)}
      onClick={(e: Event) => e.stopPropagation()}
      onClose={handleCancle}
      onOpen={() => setOpenModal(true)}
      open={openModal}
    >
      <Modal.Header>Menge anpassen</Modal.Header>
      <Modal.Content>
        <Grid columns={2} verticalAlign='middle'>
          <Grid.Column mobile={5} tablet={4} computer={4}
            style={{ paddingRight: '0' }}>
            <Form.Input
              type='number'
              value={newAmount}
              fluid
              autoFocus
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewAmount(Number(e.target.value))}
            />
          </Grid.Column>
          <Grid.Column mobile={7} tablet={8} computer={8}>
            <span> {unit} {title}</span>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          type='button'
          content='Abbrechen'
          onClick={handleCancle}
        />
        <Button
          primary
          type='submit'
          content='Anpassen'
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ChangeYieldsModal;
