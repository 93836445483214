import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type AddMealInput = {
  dateString?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  recipeId?: InputMaybe<Scalars['Float']['input']>;
};

export type AddRecipeInput = {
  recipeInput: RecipeInput;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token: Scalars['String']['output'];
};

export type Image = {
  __typename?: 'Image';
  thumbnailUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ImportPayload = {
  __typename?: 'ImportPayload';
  recipe: ImportedRecipe;
};

export type ImportedIngredient = {
  __typename?: 'ImportedIngredient';
  amount?: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  unit?: Maybe<Scalars['String']['output']>;
};

export type ImportedIngredientGroup = {
  __typename?: 'ImportedIngredientGroup';
  importedIngredients: Array<ImportedIngredient>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ImportedRecipe = {
  __typename?: 'ImportedRecipe';
  cookTime?: Maybe<Scalars['Int']['output']>;
  importedGroupedIngredients?: Maybe<Array<ImportedIngredientGroup>>;
  importedIngredients?: Maybe<Array<ImportedIngredient>>;
  instructions?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  prepTime?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  yieldUnit: Scalars['String']['output'];
  yields: Scalars['Float']['output'];
};

export type Ingredient = {
  __typename?: 'Ingredient';
  amount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  ingredientGroup?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  unit?: Maybe<Scalars['String']['output']>;
};

export type IngredientGroup = {
  __typename?: 'IngredientGroup';
  ingredients: Array<Ingredient>;
  title?: Maybe<Scalars['String']['output']>;
};

export type IngredientInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  ingredientGroup?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type Meal = {
  __typename?: 'Meal';
  dateString?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  note?: Maybe<Scalars['String']['output']>;
  recipe?: Maybe<Recipe>;
};

export type MealInput = {
  id?: InputMaybe<Scalars['Float']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  recipeId?: InputMaybe<Scalars['Float']['input']>;
};

export type MealList = {
  __typename?: 'MealList';
  dateString?: Maybe<Scalars['String']['output']>;
  meals: Array<Meal>;
};

export type MealListInput = {
  dateString?: InputMaybe<Scalars['String']['input']>;
  mealsInput: Array<MealInput>;
};

export type MealPlan = {
  __typename?: 'MealPlan';
  plannedMeals: Array<PlannedMeals>;
  reservedMeals?: Maybe<Array<Meal>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addMeal: MealList;
  addRecipe: Recipe;
  login: AuthPayload;
  removeImageFromRecipe: Recipe;
  removeMeal: Scalars['Boolean']['output'];
  removeRecipe: Scalars['Boolean']['output'];
  removeTag: Scalars['Boolean']['output'];
  updateMealPlan: MealPlan;
  updateRecipe: Recipe;
};


export type MutationAddMealArgs = {
  input: AddMealInput;
};


export type MutationAddRecipeArgs = {
  input: AddRecipeInput;
};


export type MutationLoginArgs = {
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationRemoveImageFromRecipeArgs = {
  input: RemoveImageInput;
};


export type MutationRemoveMealArgs = {
  input: RemoveMealInput;
};


export type MutationRemoveRecipeArgs = {
  input: RemoveRecipeInput;
};


export type MutationRemoveTagArgs = {
  input: RemoveTagInput;
};


export type MutationUpdateMealPlanArgs = {
  input: UpdateMealPlanInput;
};


export type MutationUpdateRecipeArgs = {
  input: UpdateRecipeInput;
};

export enum OrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum OrderByField {
  CreatedAt = 'CREATED_AT',
  Title = 'TITLE',
  UpdatedAt = 'UPDATED_AT'
}

export type PlannedMeals = {
  __typename?: 'PlannedMeals';
  dateString: Scalars['String']['output'];
  meals: Array<Meal>;
};

export type Query = {
  __typename?: 'Query';
  importRecipe: ImportPayload;
  importRecipeFromSource: ImportPayload;
  mealPlan: MealPlan;
  recipe: Recipe;
  recipes: Array<Recipe>;
  tags: Array<Tag>;
};


export type QueryImportRecipeArgs = {
  url: Scalars['String']['input'];
};


export type QueryImportRecipeFromSourceArgs = {
  source: Scalars['String']['input'];
};


export type QueryMealPlanArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRecipeArgs = {
  id: Scalars['Float']['input'];
};


export type QueryRecipesArgs = {
  filter?: InputMaybe<RecipesFilters>;
  orderBy?: InputMaybe<RecipesOrderBy>;
};

export type Recipe = {
  __typename?: 'Recipe';
  cookTime?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  groupedIngredients?: Maybe<Array<IngredientGroup>>;
  id: Scalars['Float']['output'];
  image?: Maybe<Image>;
  ingredients?: Maybe<Array<Ingredient>>;
  instructions?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  prepTime?: Maybe<Scalars['Int']['output']>;
  tagList?: Maybe<Array<Scalars['String']['output']>>;
  tags?: Maybe<Array<Tag>>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  yieldUnit: Scalars['String']['output'];
  yields: Scalars['Float']['output'];
};

export type RecipeInput = {
  cookTime?: InputMaybe<Scalars['Int']['input']>;
  imageInput?: InputMaybe<Scalars['Upload']['input']>;
  ingredientInputs?: InputMaybe<Array<IngredientInput>>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  prepTime?: InputMaybe<Scalars['Int']['input']>;
  tagList?: InputMaybe<Array<Scalars['String']['input']>>;
  title: Scalars['String']['input'];
  yieldUnit?: InputMaybe<Scalars['String']['input']>;
  yields?: InputMaybe<Scalars['Float']['input']>;
};

export type RecipesFilters = {
  ingredients?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RecipesOrderBy = {
  direction: OrderByDirection;
  field: OrderByField;
};

export type RemoveImageInput = {
  lastUpdatedAt: Scalars['DateTime']['input'];
  recipeId: Scalars['Float']['input'];
};

export type RemoveMealInput = {
  id: Scalars['Float']['input'];
};

export type RemoveRecipeInput = {
  id: Scalars['Float']['input'];
  lastUpdatedAt: Scalars['DateTime']['input'];
};

export type RemoveTagInput = {
  title: Scalars['String']['input'];
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['Float']['output'];
  recipeCount: Scalars['Float']['output'];
  title: Scalars['String']['output'];
};

export type UpdateMealPlanInput = {
  mealLists: Array<MealListInput>;
};

export type UpdateRecipeInput = {
  id: Scalars['Float']['input'];
  lastUpdatedAt: Scalars['DateTime']['input'];
  recipeInput: RecipeInput;
};

export type IngredientDataFragment = { __typename?: 'Ingredient', id: number, title: string, amount?: number | null, unit?: string | null };

export type IngredientGroupDataFragment = { __typename?: 'IngredientGroup', title?: string | null, ingredients: Array<{ __typename?: 'Ingredient', id: number, title: string, amount?: number | null, unit?: string | null }> };

export type MealDataFragment = { __typename?: 'Meal', id: number, note?: string | null, recipe?: { __typename?: 'Recipe', id: number, title: string, tagList?: Array<string> | null, image?: { __typename?: 'Image', thumbnailUrl: string } | null } | null };

export type MealPlanDataFragment = { __typename?: 'MealPlan', plannedMeals: Array<{ __typename?: 'PlannedMeals', dateString: string, meals: Array<{ __typename?: 'Meal', id: number, note?: string | null, recipe?: { __typename?: 'Recipe', id: number, title: string, tagList?: Array<string> | null, image?: { __typename?: 'Image', thumbnailUrl: string } | null } | null }> }>, reservedMeals?: Array<{ __typename?: 'Meal', id: number, note?: string | null, recipe?: { __typename?: 'Recipe', id: number, title: string, tagList?: Array<string> | null, image?: { __typename?: 'Image', thumbnailUrl: string } | null } | null }> | null };

export type PlannedMealsDataFragment = { __typename?: 'PlannedMeals', dateString: string, meals: Array<{ __typename?: 'Meal', id: number, note?: string | null, recipe?: { __typename?: 'Recipe', id: number, title: string, tagList?: Array<string> | null, image?: { __typename?: 'Image', thumbnailUrl: string } | null } | null }> };

export type RecipeDataFragment = { __typename?: 'Recipe', id: number, title: string, yields: number, yieldUnit: string, cookTime?: number | null, prepTime?: number | null, instructions?: string | null, notes?: string | null, createdAt: any, updatedAt: any, tagList?: Array<string> | null, groupedIngredients?: Array<{ __typename?: 'IngredientGroup', title?: string | null, ingredients: Array<{ __typename?: 'Ingredient', id: number, title: string, amount?: number | null, unit?: string | null }> }> | null, image?: { __typename?: 'Image', url: string } | null };

export type RecipeImportDataFragment = { __typename?: 'ImportedRecipe', title: string, yields: number, yieldUnit: string, prepTime?: number | null, cookTime?: number | null, instructions?: string | null, notes?: string | null, importedGroupedIngredients?: Array<{ __typename?: 'ImportedIngredientGroup', title?: string | null, importedIngredients: Array<{ __typename?: 'ImportedIngredient', title: string, unit?: string | null, amount?: number | null }> }> | null };

export type RecipeTeaserDataFragment = { __typename?: 'Recipe', id: number, title: string, tagList?: Array<string> | null, image?: { __typename?: 'Image', thumbnailUrl: string } | null };

export type AddMealMutationVariables = Exact<{
  input: AddMealInput;
}>;


export type AddMealMutation = { __typename?: 'Mutation', addMeal: { __typename?: 'MealList', dateString?: string | null, meals: Array<{ __typename?: 'Meal', id: number, note?: string | null, recipe?: { __typename?: 'Recipe', id: number, title: string, tagList?: Array<string> | null, image?: { __typename?: 'Image', thumbnailUrl: string } | null } | null }> } };

export type AddRecipeMutationVariables = Exact<{
  input: AddRecipeInput;
}>;


export type AddRecipeMutation = { __typename?: 'Mutation', addRecipe: { __typename?: 'Recipe', id: number, title: string, tagList?: Array<string> | null, image?: { __typename?: 'Image', thumbnailUrl: string } | null } };

export type LoginMutationVariables = Exact<{
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthPayload', token: string } };

export type RemoveImageFromRecipeMutationVariables = Exact<{
  input: RemoveImageInput;
}>;


export type RemoveImageFromRecipeMutation = { __typename?: 'Mutation', removeImageFromRecipe: { __typename?: 'Recipe', id: number, title: string, yields: number, yieldUnit: string, cookTime?: number | null, prepTime?: number | null, instructions?: string | null, notes?: string | null, createdAt: any, updatedAt: any, tagList?: Array<string> | null, groupedIngredients?: Array<{ __typename?: 'IngredientGroup', title?: string | null, ingredients: Array<{ __typename?: 'Ingredient', id: number, title: string, amount?: number | null, unit?: string | null }> }> | null, image?: { __typename?: 'Image', url: string } | null } };

export type RemoveMealMutationVariables = Exact<{
  input: RemoveMealInput;
}>;


export type RemoveMealMutation = { __typename?: 'Mutation', removeMeal: boolean };

export type RemoveRecipeMutationVariables = Exact<{
  input: RemoveRecipeInput;
}>;


export type RemoveRecipeMutation = { __typename?: 'Mutation', removeRecipe: boolean };

export type RemoveTagMutationVariables = Exact<{
  input: RemoveTagInput;
}>;


export type RemoveTagMutation = { __typename?: 'Mutation', removeTag: boolean };

export type UpdateMealPlanMutationVariables = Exact<{
  input: UpdateMealPlanInput;
}>;


export type UpdateMealPlanMutation = { __typename?: 'Mutation', updateMealPlan: { __typename?: 'MealPlan', plannedMeals: Array<{ __typename?: 'PlannedMeals', dateString: string, meals: Array<{ __typename?: 'Meal', id: number, note?: string | null, recipe?: { __typename?: 'Recipe', id: number, title: string, tagList?: Array<string> | null, image?: { __typename?: 'Image', thumbnailUrl: string } | null } | null }> }>, reservedMeals?: Array<{ __typename?: 'Meal', id: number, note?: string | null, recipe?: { __typename?: 'Recipe', id: number, title: string, tagList?: Array<string> | null, image?: { __typename?: 'Image', thumbnailUrl: string } | null } | null }> | null } };

export type UpdateRecipeMutationVariables = Exact<{
  input: UpdateRecipeInput;
}>;


export type UpdateRecipeMutation = { __typename?: 'Mutation', updateRecipe: { __typename?: 'Recipe', id: number, title: string, yields: number, yieldUnit: string, cookTime?: number | null, prepTime?: number | null, instructions?: string | null, notes?: string | null, createdAt: any, updatedAt: any, tagList?: Array<string> | null, groupedIngredients?: Array<{ __typename?: 'IngredientGroup', title?: string | null, ingredients: Array<{ __typename?: 'Ingredient', id: number, title: string, amount?: number | null, unit?: string | null }> }> | null, image?: { __typename?: 'Image', url: string } | null } };

export type ImportRecipeQueryVariables = Exact<{
  url: Scalars['String']['input'];
}>;


export type ImportRecipeQuery = { __typename?: 'Query', importRecipe: { __typename?: 'ImportPayload', recipe: { __typename?: 'ImportedRecipe', title: string, yields: number, yieldUnit: string, prepTime?: number | null, cookTime?: number | null, instructions?: string | null, notes?: string | null, importedGroupedIngredients?: Array<{ __typename?: 'ImportedIngredientGroup', title?: string | null, importedIngredients: Array<{ __typename?: 'ImportedIngredient', title: string, unit?: string | null, amount?: number | null }> }> | null } } };

export type ImportRecipeFromSourceQueryVariables = Exact<{
  source: Scalars['String']['input'];
}>;


export type ImportRecipeFromSourceQuery = { __typename?: 'Query', importRecipeFromSource: { __typename?: 'ImportPayload', recipe: { __typename?: 'ImportedRecipe', title: string, yields: number, yieldUnit: string, prepTime?: number | null, cookTime?: number | null, instructions?: string | null, notes?: string | null, importedGroupedIngredients?: Array<{ __typename?: 'ImportedIngredientGroup', title?: string | null, importedIngredients: Array<{ __typename?: 'ImportedIngredient', title: string, unit?: string | null, amount?: number | null }> }> | null } } };

export type MealPlanQueryVariables = Exact<{
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
}>;


export type MealPlanQuery = { __typename?: 'Query', mealPlan: { __typename?: 'MealPlan', plannedMeals: Array<{ __typename?: 'PlannedMeals', dateString: string, meals: Array<{ __typename?: 'Meal', id: number, note?: string | null, recipe?: { __typename?: 'Recipe', id: number, title: string, tagList?: Array<string> | null, image?: { __typename?: 'Image', thumbnailUrl: string } | null } | null }> }>, reservedMeals?: Array<{ __typename?: 'Meal', id: number, note?: string | null, recipe?: { __typename?: 'Recipe', id: number, title: string, tagList?: Array<string> | null, image?: { __typename?: 'Image', thumbnailUrl: string } | null } | null }> | null } };

export type RecipeQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type RecipeQuery = { __typename?: 'Query', recipe: { __typename?: 'Recipe', id: number, title: string, yields: number, yieldUnit: string, cookTime?: number | null, prepTime?: number | null, instructions?: string | null, notes?: string | null, createdAt: any, updatedAt: any, tagList?: Array<string> | null, groupedIngredients?: Array<{ __typename?: 'IngredientGroup', title?: string | null, ingredients: Array<{ __typename?: 'Ingredient', id: number, title: string, amount?: number | null, unit?: string | null }> }> | null, image?: { __typename?: 'Image', url: string } | null } };

export type RecipesQueryVariables = Exact<{
  orderBy: RecipesOrderBy;
  filter?: InputMaybe<RecipesFilters>;
}>;


export type RecipesQuery = { __typename?: 'Query', recipes: Array<{ __typename?: 'Recipe', id: number, title: string, tagList?: Array<string> | null, image?: { __typename?: 'Image', thumbnailUrl: string } | null }> };

export type TagsQueryVariables = Exact<{ [key: string]: never; }>;


export type TagsQuery = { __typename?: 'Query', tags: Array<{ __typename?: 'Tag', title: string, recipeCount: number }> };

export const RecipeTeaserDataFragmentDoc = gql`
    fragment RecipeTeaserData on Recipe {
  id
  title
  image {
    thumbnailUrl
  }
  tagList
}
    `;
export const MealDataFragmentDoc = gql`
    fragment MealData on Meal {
  id
  recipe {
    ...RecipeTeaserData
  }
  note
}
    ${RecipeTeaserDataFragmentDoc}`;
export const PlannedMealsDataFragmentDoc = gql`
    fragment PlannedMealsData on PlannedMeals {
  dateString
  meals {
    ...MealData
  }
}
    ${MealDataFragmentDoc}`;
export const MealPlanDataFragmentDoc = gql`
    fragment MealPlanData on MealPlan {
  plannedMeals {
    ...PlannedMealsData
  }
  reservedMeals {
    ...MealData
  }
}
    ${PlannedMealsDataFragmentDoc}
${MealDataFragmentDoc}`;
export const IngredientDataFragmentDoc = gql`
    fragment IngredientData on Ingredient {
  id
  title
  amount
  unit
}
    `;
export const IngredientGroupDataFragmentDoc = gql`
    fragment IngredientGroupData on IngredientGroup {
  title
  ingredients {
    ...IngredientData
  }
}
    ${IngredientDataFragmentDoc}`;
export const RecipeDataFragmentDoc = gql`
    fragment RecipeData on Recipe {
  id
  title
  yields
  yieldUnit
  cookTime
  prepTime
  instructions
  notes
  createdAt
  updatedAt
  groupedIngredients {
    ...IngredientGroupData
  }
  image {
    url
  }
  tagList
}
    ${IngredientGroupDataFragmentDoc}`;
export const RecipeImportDataFragmentDoc = gql`
    fragment RecipeImportData on ImportedRecipe {
  title
  yields
  yieldUnit
  prepTime
  cookTime
  importedGroupedIngredients {
    title
    importedIngredients {
      title
      unit
      amount
    }
  }
  instructions
  notes
}
    `;
export const AddMealDocument = gql`
    mutation AddMeal($input: AddMealInput!) {
  addMeal(input: $input) {
    dateString
    meals {
      ...MealData
    }
  }
}
    ${MealDataFragmentDoc}`;
export type AddMealMutationFn = Apollo.MutationFunction<AddMealMutation, AddMealMutationVariables>;

/**
 * __useAddMealMutation__
 *
 * To run a mutation, you first call `useAddMealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMealMutation, { data, loading, error }] = useAddMealMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMealMutation(baseOptions?: Apollo.MutationHookOptions<AddMealMutation, AddMealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMealMutation, AddMealMutationVariables>(AddMealDocument, options);
      }
export type AddMealMutationHookResult = ReturnType<typeof useAddMealMutation>;
export type AddMealMutationResult = Apollo.MutationResult<AddMealMutation>;
export type AddMealMutationOptions = Apollo.BaseMutationOptions<AddMealMutation, AddMealMutationVariables>;
export const AddRecipeDocument = gql`
    mutation AddRecipe($input: AddRecipeInput!) {
  addRecipe(input: $input) {
    ...RecipeTeaserData
  }
}
    ${RecipeTeaserDataFragmentDoc}`;
export type AddRecipeMutationFn = Apollo.MutationFunction<AddRecipeMutation, AddRecipeMutationVariables>;

/**
 * __useAddRecipeMutation__
 *
 * To run a mutation, you first call `useAddRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRecipeMutation, { data, loading, error }] = useAddRecipeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRecipeMutation(baseOptions?: Apollo.MutationHookOptions<AddRecipeMutation, AddRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRecipeMutation, AddRecipeMutationVariables>(AddRecipeDocument, options);
      }
export type AddRecipeMutationHookResult = ReturnType<typeof useAddRecipeMutation>;
export type AddRecipeMutationResult = Apollo.MutationResult<AddRecipeMutation>;
export type AddRecipeMutationOptions = Apollo.BaseMutationOptions<AddRecipeMutation, AddRecipeMutationVariables>;
export const LoginDocument = gql`
    mutation Login($name: String!, $password: String!) {
  login(name: $name, password: $password) {
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      name: // value for 'name'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RemoveImageFromRecipeDocument = gql`
    mutation RemoveImageFromRecipe($input: RemoveImageInput!) {
  removeImageFromRecipe(input: $input) {
    ...RecipeData
  }
}
    ${RecipeDataFragmentDoc}`;
export type RemoveImageFromRecipeMutationFn = Apollo.MutationFunction<RemoveImageFromRecipeMutation, RemoveImageFromRecipeMutationVariables>;

/**
 * __useRemoveImageFromRecipeMutation__
 *
 * To run a mutation, you first call `useRemoveImageFromRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveImageFromRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeImageFromRecipeMutation, { data, loading, error }] = useRemoveImageFromRecipeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveImageFromRecipeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveImageFromRecipeMutation, RemoveImageFromRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveImageFromRecipeMutation, RemoveImageFromRecipeMutationVariables>(RemoveImageFromRecipeDocument, options);
      }
export type RemoveImageFromRecipeMutationHookResult = ReturnType<typeof useRemoveImageFromRecipeMutation>;
export type RemoveImageFromRecipeMutationResult = Apollo.MutationResult<RemoveImageFromRecipeMutation>;
export type RemoveImageFromRecipeMutationOptions = Apollo.BaseMutationOptions<RemoveImageFromRecipeMutation, RemoveImageFromRecipeMutationVariables>;
export const RemoveMealDocument = gql`
    mutation RemoveMeal($input: RemoveMealInput!) {
  removeMeal(input: $input)
}
    `;
export type RemoveMealMutationFn = Apollo.MutationFunction<RemoveMealMutation, RemoveMealMutationVariables>;

/**
 * __useRemoveMealMutation__
 *
 * To run a mutation, you first call `useRemoveMealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMealMutation, { data, loading, error }] = useRemoveMealMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMealMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMealMutation, RemoveMealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMealMutation, RemoveMealMutationVariables>(RemoveMealDocument, options);
      }
export type RemoveMealMutationHookResult = ReturnType<typeof useRemoveMealMutation>;
export type RemoveMealMutationResult = Apollo.MutationResult<RemoveMealMutation>;
export type RemoveMealMutationOptions = Apollo.BaseMutationOptions<RemoveMealMutation, RemoveMealMutationVariables>;
export const RemoveRecipeDocument = gql`
    mutation RemoveRecipe($input: RemoveRecipeInput!) {
  removeRecipe(input: $input)
}
    `;
export type RemoveRecipeMutationFn = Apollo.MutationFunction<RemoveRecipeMutation, RemoveRecipeMutationVariables>;

/**
 * __useRemoveRecipeMutation__
 *
 * To run a mutation, you first call `useRemoveRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRecipeMutation, { data, loading, error }] = useRemoveRecipeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveRecipeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRecipeMutation, RemoveRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRecipeMutation, RemoveRecipeMutationVariables>(RemoveRecipeDocument, options);
      }
export type RemoveRecipeMutationHookResult = ReturnType<typeof useRemoveRecipeMutation>;
export type RemoveRecipeMutationResult = Apollo.MutationResult<RemoveRecipeMutation>;
export type RemoveRecipeMutationOptions = Apollo.BaseMutationOptions<RemoveRecipeMutation, RemoveRecipeMutationVariables>;
export const RemoveTagDocument = gql`
    mutation RemoveTag($input: RemoveTagInput!) {
  removeTag(input: $input)
}
    `;
export type RemoveTagMutationFn = Apollo.MutationFunction<RemoveTagMutation, RemoveTagMutationVariables>;

/**
 * __useRemoveTagMutation__
 *
 * To run a mutation, you first call `useRemoveTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagMutation, { data, loading, error }] = useRemoveTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTagMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTagMutation, RemoveTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTagMutation, RemoveTagMutationVariables>(RemoveTagDocument, options);
      }
export type RemoveTagMutationHookResult = ReturnType<typeof useRemoveTagMutation>;
export type RemoveTagMutationResult = Apollo.MutationResult<RemoveTagMutation>;
export type RemoveTagMutationOptions = Apollo.BaseMutationOptions<RemoveTagMutation, RemoveTagMutationVariables>;
export const UpdateMealPlanDocument = gql`
    mutation UpdateMealPlan($input: UpdateMealPlanInput!) {
  updateMealPlan(input: $input) {
    ...MealPlanData
  }
}
    ${MealPlanDataFragmentDoc}`;
export type UpdateMealPlanMutationFn = Apollo.MutationFunction<UpdateMealPlanMutation, UpdateMealPlanMutationVariables>;

/**
 * __useUpdateMealPlanMutation__
 *
 * To run a mutation, you first call `useUpdateMealPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMealPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMealPlanMutation, { data, loading, error }] = useUpdateMealPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMealPlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMealPlanMutation, UpdateMealPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMealPlanMutation, UpdateMealPlanMutationVariables>(UpdateMealPlanDocument, options);
      }
export type UpdateMealPlanMutationHookResult = ReturnType<typeof useUpdateMealPlanMutation>;
export type UpdateMealPlanMutationResult = Apollo.MutationResult<UpdateMealPlanMutation>;
export type UpdateMealPlanMutationOptions = Apollo.BaseMutationOptions<UpdateMealPlanMutation, UpdateMealPlanMutationVariables>;
export const UpdateRecipeDocument = gql`
    mutation UpdateRecipe($input: UpdateRecipeInput!) {
  updateRecipe(input: $input) {
    ...RecipeData
  }
}
    ${RecipeDataFragmentDoc}`;
export type UpdateRecipeMutationFn = Apollo.MutationFunction<UpdateRecipeMutation, UpdateRecipeMutationVariables>;

/**
 * __useUpdateRecipeMutation__
 *
 * To run a mutation, you first call `useUpdateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecipeMutation, { data, loading, error }] = useUpdateRecipeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecipeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecipeMutation, UpdateRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecipeMutation, UpdateRecipeMutationVariables>(UpdateRecipeDocument, options);
      }
export type UpdateRecipeMutationHookResult = ReturnType<typeof useUpdateRecipeMutation>;
export type UpdateRecipeMutationResult = Apollo.MutationResult<UpdateRecipeMutation>;
export type UpdateRecipeMutationOptions = Apollo.BaseMutationOptions<UpdateRecipeMutation, UpdateRecipeMutationVariables>;
export const ImportRecipeDocument = gql`
    query ImportRecipe($url: String!) {
  importRecipe(url: $url) {
    recipe {
      ...RecipeImportData
    }
  }
}
    ${RecipeImportDataFragmentDoc}`;

/**
 * __useImportRecipeQuery__
 *
 * To run a query within a React component, call `useImportRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportRecipeQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useImportRecipeQuery(baseOptions: Apollo.QueryHookOptions<ImportRecipeQuery, ImportRecipeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImportRecipeQuery, ImportRecipeQueryVariables>(ImportRecipeDocument, options);
      }
export function useImportRecipeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImportRecipeQuery, ImportRecipeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImportRecipeQuery, ImportRecipeQueryVariables>(ImportRecipeDocument, options);
        }
export type ImportRecipeQueryHookResult = ReturnType<typeof useImportRecipeQuery>;
export type ImportRecipeLazyQueryHookResult = ReturnType<typeof useImportRecipeLazyQuery>;
export type ImportRecipeQueryResult = Apollo.QueryResult<ImportRecipeQuery, ImportRecipeQueryVariables>;
export const ImportRecipeFromSourceDocument = gql`
    query ImportRecipeFromSource($source: String!) {
  importRecipeFromSource(source: $source) {
    recipe {
      ...RecipeImportData
    }
  }
}
    ${RecipeImportDataFragmentDoc}`;

/**
 * __useImportRecipeFromSourceQuery__
 *
 * To run a query within a React component, call `useImportRecipeFromSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportRecipeFromSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportRecipeFromSourceQuery({
 *   variables: {
 *      source: // value for 'source'
 *   },
 * });
 */
export function useImportRecipeFromSourceQuery(baseOptions: Apollo.QueryHookOptions<ImportRecipeFromSourceQuery, ImportRecipeFromSourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImportRecipeFromSourceQuery, ImportRecipeFromSourceQueryVariables>(ImportRecipeFromSourceDocument, options);
      }
export function useImportRecipeFromSourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImportRecipeFromSourceQuery, ImportRecipeFromSourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImportRecipeFromSourceQuery, ImportRecipeFromSourceQueryVariables>(ImportRecipeFromSourceDocument, options);
        }
export type ImportRecipeFromSourceQueryHookResult = ReturnType<typeof useImportRecipeFromSourceQuery>;
export type ImportRecipeFromSourceLazyQueryHookResult = ReturnType<typeof useImportRecipeFromSourceLazyQuery>;
export type ImportRecipeFromSourceQueryResult = Apollo.QueryResult<ImportRecipeFromSourceQuery, ImportRecipeFromSourceQueryVariables>;
export const MealPlanDocument = gql`
    query MealPlan($from: String, $to: String) {
  mealPlan(from: $from, to: $to) {
    ...MealPlanData
  }
}
    ${MealPlanDataFragmentDoc}`;

/**
 * __useMealPlanQuery__
 *
 * To run a query within a React component, call `useMealPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useMealPlanQuery(baseOptions?: Apollo.QueryHookOptions<MealPlanQuery, MealPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MealPlanQuery, MealPlanQueryVariables>(MealPlanDocument, options);
      }
export function useMealPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MealPlanQuery, MealPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MealPlanQuery, MealPlanQueryVariables>(MealPlanDocument, options);
        }
export type MealPlanQueryHookResult = ReturnType<typeof useMealPlanQuery>;
export type MealPlanLazyQueryHookResult = ReturnType<typeof useMealPlanLazyQuery>;
export type MealPlanQueryResult = Apollo.QueryResult<MealPlanQuery, MealPlanQueryVariables>;
export const RecipeDocument = gql`
    query Recipe($id: Float!) {
  recipe(id: $id) {
    ...RecipeData
  }
}
    ${RecipeDataFragmentDoc}`;

/**
 * __useRecipeQuery__
 *
 * To run a query within a React component, call `useRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecipeQuery(baseOptions: Apollo.QueryHookOptions<RecipeQuery, RecipeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecipeQuery, RecipeQueryVariables>(RecipeDocument, options);
      }
export function useRecipeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipeQuery, RecipeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecipeQuery, RecipeQueryVariables>(RecipeDocument, options);
        }
export type RecipeQueryHookResult = ReturnType<typeof useRecipeQuery>;
export type RecipeLazyQueryHookResult = ReturnType<typeof useRecipeLazyQuery>;
export type RecipeQueryResult = Apollo.QueryResult<RecipeQuery, RecipeQueryVariables>;
export const RecipesDocument = gql`
    query Recipes($orderBy: RecipesOrderBy!, $filter: RecipesFilters) {
  recipes(orderBy: $orderBy, filter: $filter) {
    ...RecipeTeaserData
  }
}
    ${RecipeTeaserDataFragmentDoc}`;

/**
 * __useRecipesQuery__
 *
 * To run a query within a React component, call `useRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRecipesQuery(baseOptions: Apollo.QueryHookOptions<RecipesQuery, RecipesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecipesQuery, RecipesQueryVariables>(RecipesDocument, options);
      }
export function useRecipesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipesQuery, RecipesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecipesQuery, RecipesQueryVariables>(RecipesDocument, options);
        }
export type RecipesQueryHookResult = ReturnType<typeof useRecipesQuery>;
export type RecipesLazyQueryHookResult = ReturnType<typeof useRecipesLazyQuery>;
export type RecipesQueryResult = Apollo.QueryResult<RecipesQuery, RecipesQueryVariables>;
export const TagsDocument = gql`
    query Tags {
  tags {
    title
    recipeCount
  }
}
    `;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;