import React from 'react';
import { DropdownProps, Form } from 'semantic-ui-react';
import { useTagsQuery } from '../generated/graphql';

type RecipeTag = {
  text: string,
  value: string;
};

interface Props {
  allowAdditions?: boolean,
  tags: string[],
  setTags: (tags: string[]) => void,
};

const RecipeTags = ({ allowAdditions = true, tags, setTags }: Props) => {
  const { data, error, loading } = useTagsQuery();

  const initialTagOptions: RecipeTag[] = React.useMemo(() => {
    return data?.tags
      .map(tag => tag.title)
      .map(tagTitle => ({ text: tagTitle, value: tagTitle })) || [];
  }, [data?.tags]);

  const [tagOptions, setTagOptions] = React.useState<RecipeTag[]>(initialTagOptions);

  React.useEffect(() => {
    setTagOptions(() => initialTagOptions);
  }, [initialTagOptions]);

  const handleAddition = (e: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    setTagOptions((prevTags: RecipeTag[]) => {
      return [{ text: value as string, value: value as string }, ...prevTags];
    });
  };

  const handleChange = (e: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    setTags(Array.isArray(value) ? value.map(val => val as string) : [value as string]);
  };

  return (
    <>
      <Form.Dropdown
        options={tagOptions}
        search
        selection
        fluid
        multiple
        allowAdditions={allowAdditions}
        loading={loading}
        error={error}
        value={tags}
        onAddItem={handleAddition}
        onChange={handleChange}
      />
    </>
  );
};

export default RecipeTags;
