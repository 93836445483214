import { Image } from 'semantic-ui-react';
import { RecipeTeaserDataFragment } from '../generated/graphql';

type Props = {
  recipe?: RecipeTeaserDataFragment;
};

const RecipeThumbnail = ({ recipe }: Props) => {
  if (!!recipe?.image?.thumbnailUrl) {
    return (
      <Image
        src={process.env.REACT_APP_REZEPTDB_API_BASE_URL + recipe.image.thumbnailUrl}
        alt={recipe.title}
        size='tiny'
        rounded
        verticalAlign='middle'
      />);
  } else {
    return (
      <Image as='div'
        className='recipe-image'
        size='tiny'
        rounded
        verticalAlign='middle'
      />
    );
  }
};

export default RecipeThumbnail;
