import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { Container, Form, Header } from 'semantic-ui-react';
import RecipeTags from '../components/RecipeTags';
import SearchResult from '../components/SearchResult';

const Search = () => {

  document.title = 'Suche - RezeptDB';

  const [searchParams, setSearchParams] = useSearchParams();
  const currentIngredientsQuery = searchParams.get('ings')?.trim() || '';
  const currentTags = searchParams.get('tags')?.trim().split(',').filter(val => !!val) || [];

  return (
    <Container>
      <SearchForm
        initalIngredientsQuery={currentIngredientsQuery}
        initialTags={currentTags}
        setSearchParams={setSearchParams}
      />
      {(!!currentIngredientsQuery || currentTags.length > 0) &&
        <SearchResult
          ingredients={currentIngredientsQuery.split(' ').filter(val => !!val)}
          tags={currentTags}
        />}
    </Container>
  );
};

interface SearchFormProps {
  initalIngredientsQuery: string,
  initialTags: string[],
  setSearchParams: (value: string) => void,
}

const SearchForm = ({ initalIngredientsQuery, initialTags, setSearchParams }: SearchFormProps) => {

  const formik = useFormik({
    initialValues: {
      ings: initalIngredientsQuery,
      tags: initialTags,
    },
    onSubmit: (values) => {
      setSearchParams(`ings=${values.ings}&tags=${values.tags.join(',')}`);
    }
  });
  return (
    <Form
      onSubmit={formik.handleSubmit} >
      <Header>Zutaten</Header>
      <Form.Input
        {...formik.getFieldProps('ings')}
        autoFocus
      />

      <Header>Rezept-Tags</Header>
      <RecipeTags
        allowAdditions={false}
        tags={formik.getFieldProps('tags').value}
        setTags={(value: string[]) => formik.setFieldValue('tags', value)}
      />
      <Form.Button primary type='submit' content='Suchen' />
    </Form>
  );
};

export default Search;
