import { useFormik } from 'formik';
import { Navigate } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useAddRecipeMutation, RecipeTeaserDataFragmentDoc, TagsDocument } from '../generated/graphql';
import { IngredientGroup } from '../components/recipeInputForm/ingredientGroupHelper';
import { mapToIngredientInputs } from './recipeInputForm/ingredientGroupHelper';
import RecipeInputForm from './recipeInputForm/RecipeInputForm';

interface Props {
  recipe: {
    title: string,
    yields: number,
    yieldUnit: string,
    prepTime?: number,
    cookTime?: number,
    ingredientGroups: IngredientGroup[],
    instructions?: string,
    notes?: string,
    image?: null,
    tags: string[],
  },
}

const AddRecipe = ({ recipe }: Props) => {

  const [addRecipe, { error, data, called, loading }] = useAddRecipeMutation({
    onError: (error) => {
      console.log(error);
    },
    refetchQueries: [{ query: TagsDocument }],
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          recipes(existingRecipes = []) {
            const newRecipeRef = cache.writeFragment({
              data: data?.addRecipe,
              fragment: RecipeTeaserDataFragmentDoc,
            });
            return [...existingRecipes, newRecipeRef];
          }
        }
      });
    }
  });

  const formik = useFormik({
    initialValues: recipe,
    onSubmit: (values) => {
      addRecipe({
        variables: {
          input: {
            recipeInput: {
              title: values.title,
              yields: values.yields || undefined,
              yieldUnit: values.yieldUnit || undefined,
              prepTime: !!values.prepTime ? Number(values.prepTime) * 60 : undefined,
              cookTime: !!values.cookTime ? Number(values.cookTime) * 60 : undefined,
              ingredientInputs: mapToIngredientInputs(values.ingredientGroups),
              instructions: values.instructions || undefined,
              notes: values.notes || undefined,
              imageInput: values.image || undefined,
              tagList: values.tags || [],
            }
          }
        }
      });
    }
  });

  if (loading) {
    return (
      <Dimmer inverted active>
        <Loader />
      </Dimmer>
    );
  }

  if (!!called && !!data && data.addRecipe.id) {
    return <Navigate to={`/recipe/${data.addRecipe.id}`} />;
  }

  return <RecipeInputForm
    formik={formik}
    error={error}
    redirectUrlOnCancle='/'
  />;
};


export default AddRecipe;
