import React from 'react';
import { useNavigationType } from 'react-router';
import { Dimmer, Input, Loader, Message, Sticky } from 'semantic-ui-react';
import { useRecipesQuery, OrderByField, OrderByDirection } from '../generated/graphql';
import RecipeList from '../components/RecipeList';
import { useIsRecudeNavBar } from '../useMediaQuery';

const Home = () => {

  const scrollOffsetKey = 'home-scroll-offset';
  const filterQueryKey = 'home-filter-query';

  document.title = 'RezeptDB';

  const [filterQuery, setFilterQuery] = React.useState('');
  const recudeNavBar = useIsRecudeNavBar();
  const { data, error, loading } = useRecipesQuery({
    variables: {
      orderBy: {
        field: OrderByField.Title,
        direction: OrderByDirection.Asc
      }
    }
  });

  const navType = useNavigationType();
  React.useEffect(() => {
    if (navType === 'POP') {
      const savedFilterQuery = sessionStorage.getItem(filterQueryKey);
      if (savedFilterQuery) {
        sessionStorage.removeItem(filterQueryKey);
        setFilterQuery(savedFilterQuery);
      }
    }
  }, [navType]);

  if (loading) {
    return (
      <Dimmer inverted active>
        <Loader />
      </Dimmer>
    );
  }

  if (error || !data) {
    return (<Message error
      header='Da ist etwas schiefgelaufen!'
      content={error?.message} />);
  }

  const stickyContext = React.createRef<HTMLObjectElement>();

  return (
    <div ref={stickyContext}>
      <Sticky context={stickyContext}>
        <Input
          fluid={recudeNavBar}
          style={recudeNavBar ? {} : { float: 'right', clear: 'both' }}
          icon='search'
          placeholder='Filter'
          value={filterQuery}
          autoFocus={!recudeNavBar}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFilterQuery(e.target.value.toLocaleLowerCase());
          }}
        />
      </Sticky>
      <RecipeList
        data={data}
        filterQuery={filterQuery}
        scrollOffsetKey={scrollOffsetKey}
        onRecipeClick={() => {
          sessionStorage.setItem(scrollOffsetKey, String(window.scrollY));
          sessionStorage.setItem(filterQueryKey, filterQuery);
        }}
      />
    </div>
  );
};

export default Home;
