import React from 'react';
import ReactMarkdown from 'react-markdown';
import { List, Segment } from 'semantic-ui-react';
import { useMarkableColor } from '../../useMarkableColor';
import MarkableListItem from './MarkableListItem';

const bulletPointRegExp = RegExp('^(- |\\* |• )');

interface Props {
  text: string,
  isLastParagraph: boolean,
}

const MarkableParagraph = ({ text, isLastParagraph }: Props) => {
  const [isMarked, setIsMarked] = React.useState(false);
  const [unmarkAllChildren, setUnmarkAllChildren] = React.useState(false);
  const [markedItemsCount, setMarkedItemsCount] = React.useState(0);

  const lines = text.split(/(?:\n|\r\n)/);
  const content = [];
  while (lines.length > 0) {
    if (bulletPointRegExp.test(lines[0].trim())) {
      const listElements = [];
      while (lines.length > 0 && bulletPointRegExp.test(lines[0].trim())) {
        const element = lines.shift();
        if (element) {
          listElements.push(element.replace(bulletPointRegExp, ''));
        }
      }
      content.push(
        <List key={content.length}>
          {listElements.map((element, i) => (
            <MarkableListItem
              key={i}
              text={element}
              mark={isMarked}
              unmark={unmarkAllChildren}
              setMarkedItemsCount={setMarkedItemsCount}
            />
          ))}
        </List>
      );
    } else {
      content.push(<ReactMarkdown key={content.length}>{lines.shift() || ''}</ReactMarkdown>);
    }
  }

  const itemCount = content
    .filter(c => c.type.name === List.name)
    .flatMap(c => c.props.children)
    .length;

  React.useEffect(() => {
    if (isMarked) {
      setMarkedItemsCount(itemCount);
    }
  }, [isMarked, itemCount]);

  React.useEffect(() => {
    if (unmarkAllChildren) {
      setMarkedItemsCount(0);
    }
  }, [unmarkAllChildren]);

  React.useEffect(() => {
    setIsMarked(itemCount !== 0 && markedItemsCount === itemCount);
    if (markedItemsCount !== 0) {
      setUnmarkAllChildren(false);
    }
  }, [itemCount, markedItemsCount]);

  return (
    <Segment
      attached={isLastParagraph ? 'bottom' : true}
      style={{ backgroundColor: useMarkableColor(isMarked) }}
      onClick={() => {
        setUnmarkAllChildren(isMarked);
        setIsMarked(!isMarked);
      }}
    >
      {content}
    </Segment>
  );
};

export default MarkableParagraph;
