import React from 'react';
import { Container, Divider, Grid, Message, Segment, Tab } from 'semantic-ui-react';
import { reservedListId } from '../../constants';
import { MealDataFragment, MealListInput, MealPlanDataFragment } from '../../generated/graphql';
import { useIsComputer } from '../../useMediaQuery';
import PlanningView from './PlanningView';
import { Meal } from './MealList';
import ReservedMealList from './ReservedMealList';
import AddNoteForm from './AddNoteForm';
import { useUpdateMealPlanMutationWithCacheUpdate } from '../../useUpdateMealPlanMutationWithCacheUpdate';
import { DateTime } from 'luxon';

type Props = {
  mealPlan: MealPlanDataFragment,
};

const MealPlanView = ({ mealPlan }: Props) => {

  const isComputer = useIsComputer();
  const [isInvalidMove, setIsInvalidMove] = React.useState<boolean>();
  const [updateMealPlan, { called, error }] = useUpdateMealPlanMutationWithCacheUpdate();

  if (!!called && !!error) {
    return (<Message error
      header='Da ist etwas schiefgelaufen!'
      content={error?.message}
    />);
  }

  const getFromList = (
    fromListId: DateTime | typeof reservedListId,
    movedMealId: number
  ): MealListInput | undefined => {
    const toFilteredListInput = (meals: MealDataFragment[]) => meals
      .filter(meal => meal.id !== movedMealId)
      .map(meal => ({
        id: meal.id,
        recipeId: meal.recipe?.id,
        note: meal.note,
      }));

    if (fromListId !== reservedListId) {
      const fromDay = mealPlan.plannedMeals
        .find(plannedMeals => plannedMeals.dateString === fromListId.toISODate());
      if (fromDay) {
        return {
          dateString: fromDay.dateString,
          mealsInput: toFilteredListInput(fromDay.meals),
        };
      }
    }
    if (!!mealPlan.reservedMeals) {
      return {
        mealsInput: toFilteredListInput(mealPlan.reservedMeals),
      };
    }
  };

  const getToList = (
    toListId: DateTime | typeof reservedListId,
    movedMeal: Meal,
  ): MealListInput => {
    const toConcatedListInput = (meals?: MealDataFragment[]) => (meals || [])
      .map(meal => ({
        id: meal.id,
        recipeId: meal.recipe?.id,
        note: meal.note,
      })).concat([{
        id: movedMeal.id,
        recipeId: movedMeal.recipeId,
        note: movedMeal.note,
      }]);

    if (toListId !== reservedListId) {
      const toDayId = toListId.toISODate();
      return {
        dateString: toDayId,
        mealsInput: toConcatedListInput(
          mealPlan.plannedMeals
            .find(plannesMeals => plannesMeals.dateString === toDayId)?.meals
        ),
      };
    }
    return {
      mealsInput: toConcatedListInput(mealPlan?.reservedMeals || undefined)
    };
  };

  const moveMeal = (
    fromListId: DateTime | typeof reservedListId,
    toListId: DateTime | typeof reservedListId,
    movedMeal: Meal,
  ) => {
    const fromList: MealListInput | undefined = getFromList(fromListId, movedMeal.id);
    if (!fromList) {
      setIsInvalidMove(true);
      throw new Error(`Unable to determine from-list for fromDayId "${fromListId}", toDayId "${toListId}" and movedMeal "${JSON.stringify(movedMeal)}"`);
    }
    updateMealPlan({
      variables: {
        input: {
          mealLists: [fromList, getToList(toListId, movedMeal)],
        }
      }
    });
  };

  const planning = (
    <PlanningView
      plannedMeals={mealPlan.plannedMeals}
      moveMeal={moveMeal}
    />
  );
  const reservedMealList = (
    <ReservedMealList
      reservedMeals={mealPlan.reservedMeals || []}
      moveMeal={moveMeal}
    />
  );

  return (
    <Container>
      <AddNoteForm />
      {isInvalidMove &&
        <Message error
          header='Da ist etwas schiefgelaufen!'
        />}
      <Divider hidden />
      {isComputer ?
        <Grid columns={2}>
          <Grid.Column>
            <Segment>
              {planning}
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              {reservedMealList}
            </Segment>
          </Grid.Column>
        </Grid> :
        <Tab panes={[
          {
            menuItem: 'Planung',
            render: () => <Tab.Pane>{planning}</Tab.Pane>
          },
          {
            menuItem: 'Vorgemerkt',
            render: () => <Tab.Pane> {reservedMealList} </Tab.Pane>
          },
        ]} />
      }
    </Container>
  );
};

export default MealPlanView;
