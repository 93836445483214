import ReactMarkdown from 'react-markdown';
import { Header, Segment } from 'semantic-ui-react';
import MarkableParagraph from './MarkableParagraph';

interface Props {
  heading: string,
  text: string,
  isMarkable?: boolean;
}

const RecipeText = ({ heading, text, isMarkable }: Props) => {

  const textSegments = text.split(/(\n\n|\r\n\r\n)/)
    .map(textSegment => textSegment.trim());
  return (
    <>
      <Header as='h4' attached='top' block>
        {heading}
      </Header>
      {textSegments.map((textSegment, idx) => !!textSegment && (
        <Paragraph
          key={idx}
          text={textSegment}
          isLastParagraph={textSegments.length === idx + 1}
          isMarkable={isMarkable}
        />
      ))}
    </>
  );
};

interface ParagraphProps {
  text: string,
  isLastParagraph: boolean,
  isMarkable?: boolean,
}

const Paragraph = ({ text, isLastParagraph, isMarkable }: ParagraphProps) => {
  return (
    isMarkable ?
      <MarkableParagraph text={text} isLastParagraph={isLastParagraph} /> :
      <Segment attached={isLastParagraph ? 'bottom' : true} >
        <ReactMarkdown className='markdown'>{text}</ReactMarkdown>
      </Segment>
  );
};

export default RecipeText;
