import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FieldInputProps } from 'formik';
import { Segment, Grid, Header, Form } from 'semantic-ui-react';
import SortableIngredientRow from './SortableIngredientRow';

interface Props {
  formik: {
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>,
  },
  lastIngredientGroup: boolean,
  groupIdx: number,
  sortableItems: string[],
  addIngredient: (groupIdx: number, ingIdx: number) => () => void,
  addIngredientGroup: (groupIdx: number) => () => void,
  isDragging: (itemId: string) => boolean,
}

const IngredientGroupContainer = ({ formik, lastIngredientGroup, groupIdx, sortableItems, addIngredient, addIngredientGroup, isDragging }: Props) => (
  <>
    {groupIdx === 0 ?
      <Header as='h4' attached='top' block>
        Zutaten
      </Header> :
      <Header as='h5' attached block>
        <Form.Input
          {...formik.getFieldProps(`ingredientGroups[${groupIdx}].title`)}
          placeholder='Zutatengruppe'
          onInput={addIngredientGroup(groupIdx)}
        />
      </Header>}

    <Segment attached={lastIngredientGroup ? 'bottom' : true}>
      <Grid columns={2}>

        <SortableContext
          items={sortableItems}
          strategy={verticalListSortingStrategy}
        >
          {sortableItems
            .map((itemId, idx) => (
              <SortableIngredientRow
                key={itemId}
                id={itemId}
                isDisabled={idx === sortableItems.length - 1}
                isDragging={isDragging(itemId)}
                formik={formik}
                addIngredient={addIngredient}
              />
            ))}
        </SortableContext>
      </Grid>
    </Segment>
  </>
);

export default IngredientGroupContainer;
