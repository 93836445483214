import { Link } from 'react-router-dom';
import { Button, Dimmer, Header, List, Loader } from 'semantic-ui-react';
import DeleteTagModal from '../components/DeleteTagModal';
import { useTagsQuery } from '../generated/graphql';

interface Props {
  isLoggedIn: boolean,
}

const TagsOverview = ({ isLoggedIn }: Props) => {
  document.title = 'Rezept-Tags - RezeptDB';

  const { data, error, loading } = useTagsQuery();

  if (loading) {
    return (
      <Dimmer inverted active>
        <Loader />
      </Dimmer>
    );
  }

  if (error || !data) {
    return <div>ERROR: {error?.message}</div>;
  }

  return (
    <>
      <Header>
        Rezept-Tags
      </Header>
      <List divided>
        {!!data.tags && [...data.tags]
          .sort((t1, t2) => t2.recipeCount - t1.recipeCount)
          .map((tag, i) =>
            <List.Item key={i} >
              {isLoggedIn && (
                <List.Content floated='right'>
                  <DeleteTagModal
                    title={tag.title}
                    trigger={<Button
                      basic
                      compact
                      color='red'
                      icon='delete'
                      size='small'
                    />}
                  />
                </List.Content>
              )}
              <List.Content>
                <TagHeader tag={tag} />
                <List.Description>
                  {tag.recipeCount} {tag.recipeCount === 1 ? 'Rezept' : 'Rezepte'}
                </List.Description>
              </List.Content>
            </List.Item>
          )
        }
      </List>
    </>
  );
};

interface TagProps {
  tag: {
    title: string,
    recipeCount: number,
  },
}

const TagHeader = ({ tag }: TagProps) => {
  if (tag.recipeCount > 0) {
    return <List.Header as={Link} to={`/tag/${tag.title}`}>
      {tag.title}
    </List.Header>;
  }
  return <List.Header>
    {tag.title}
  </List.Header>;
};

export default TagsOverview;
