import { ReactElement } from 'react';
import { Message, Modal } from 'semantic-ui-react';
import { useRemoveTagMutation } from '../generated/graphql';

interface Props {
  title: string,
  trigger: ReactElement;
}

const DeleteTagModal = ({ title, trigger }: Props) => {
  const [removeTag, { error }] = useRemoveTagMutation({
    onError: (error) => {
      console.log(error);
    },
    update: (cache, { data }) => {
      if (data?.removeTag) {
        cache.modify({
          fields: {
            tags(exitingTags = []) {
              return exitingTags.filter((tag: { title: string; }) => tag.title !== title);
            }
          }
        },
        );
      }
    }
  });

  const deleteTag = () => {
    removeTag({
      variables: {
        input: {
          title,
        }
      }
    });
  };

  if (error) {
    return (<Message error
      header='Da ist etwas schiefgelaufen!'
      content={error.message} />);
  }

  return (<Modal
    trigger={trigger}
    header='Rezept-Tag löschen'
    content={`Soll der Rezept-Tag "${title}" endgültg gelöscht werden?`}
    actions={[
      'Abbrechen',
      { key: 'delete', content: 'Löschen', negative: true, onClick: deleteTag }
    ]}
  />);
};

export default DeleteTagModal;
