import React from 'react';
import { Button, Label } from 'semantic-ui-react';

interface Props {
  setImage: (image: File) => void,
  setImagePreview: (imagePreview: string) => void,
  setIsImagePlaceholder: (isImagePlaceholder: boolean) => void,
}

const RecipeImage = ({ setImage, setImagePreview, setIsImagePlaceholder }: Props) => {
  const [selectedFile, setSelectedFile] = React.useState<File | undefined>();

  // create a preview as a side effect, whenever selected file is changed
  React.useEffect(() => {
    if (!selectedFile) {
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setImagePreview(objectUrl);
    setIsImagePlaceholder(false);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile, setImagePreview, setIsImagePlaceholder]);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    const file = e.target.files[0];
    setSelectedFile(file);
    setImage(file);
  };

  let hiddenInput: HTMLInputElement | null = null;

  return (<>
    <Button
      as='div'
      labelPosition='right'
      style={{ width: '100%' }}
    >
      <Button
        type='button'
        icon='upload'
        htmlFor='file'
        onClick={() => hiddenInput!.click()}
      />
      <Label
        basic
        children='Wähle ein Bild'
        htmlFor='file'
        onClick={() => hiddenInput!.click()}
        style={{ cursor: 'pointer', width: '100%' }}
      />
    </Button>
    <input
      hidden
      ref={el => {
        hiddenInput = el!;
      }}
      type='file'
      accept='image/*'
      id='file'
      onChange={onSelectFile}
    />
  </>);
};

export default RecipeImage;
