import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { FieldInputProps } from 'formik';
import { Grid, Form, Button } from 'semantic-ui-react';

interface Props {
  itemId: string,
  formik: {
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>,
  },
  addIngredient: (groudIdx: number, ingIdx: number) => () => void,
  dragHandleProps?: SyntheticListenerMap | { disabled: boolean; },
  [key: string]: any;
}

const IngredientRow = ({ itemId, formik, addIngredient, dragHandleProps, ...props }: Props) => {
  const [groupIdx, ingIdx] = itemId.split('-');
  const formikIngredientKey = `ingredientGroups[${groupIdx}].ingredients[${ingIdx}]`;
  const addIng = addIngredient(Number(groupIdx), Number(ingIdx));
  return (
    <Grid.Row className='ingredient-row' {...props}>
      <Grid.Column mobile={8} tablet={8} computer={6}>
        <Button as='div'
          icon='sort'
          basic
          {...dragHandleProps}
        />
        <Form.Input
          {...formik.getFieldProps(`${formikIngredientKey}.amount`)}
          placeholder='Menge'
          type='number'
        />
      </Grid.Column>

      <Grid.Column mobile={8} tablet={8} computer={3}>
        <Form.Input
          {...formik.getFieldProps(`${formikIngredientKey}.unit`)}
          placeholder='Einheit'
        />
      </Grid.Column>

      <Grid.Column only='mobile tablet' width={16} style={{ paddingTop: '1rem' }}>
        <Form.Input
          {...formik.getFieldProps(`${formikIngredientKey}.title`)}
          placeholder='Zutat'
          onInput={addIng}
        />
      </Grid.Column>
      <Grid.Column only='computer' width={7} style={{ paddingLeft: '0' }} >
        <Form.Input
          {...formik.getFieldProps(`${formikIngredientKey}.title`)}
          placeholder='Zutat'
          onInput={addIng}
        />
      </Grid.Column>
      {formik.getFieldProps(`${formikIngredientKey}.id`).value ?
        <Form.Input
          {...formik.getFieldProps(`${formikIngredientKey}.id`)}
          type='hidden'
        /> :
        null}
    </Grid.Row>
  );
};

export default IngredientRow;
