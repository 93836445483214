import { IngredientInput } from '../../generated/graphql';

export interface Ingredient {
  id?: number,
  title: string,
  amount: string,
  unit: string,
}

export interface IngredientGroup {
  title?: string,
  ingredients: Ingredient[];
}

function toIngredientInputs(ingredientGroup: IngredientGroup): IngredientInput[] {
  return ingredientGroup.ingredients
    .filter(ingredient => !!ingredient.title)
    .map(ingredient => ({
      id: ingredient.id,
      title: ingredient.title,
      amount: !!ingredient.amount ? Number(ingredient.amount) : undefined,
      unit: ingredient.unit || undefined,
      ingredientGroup: ingredientGroup.title || undefined
    }));
}

export function mapToIngredientInputs(ingredientGroups: IngredientGroup[]): IngredientInput[] {
  return ingredientGroups
    .map(ingredientGroup => toIngredientInputs(ingredientGroup))
    .reduce((a, b) => a.concat(b));
}
