import { DateTime } from 'luxon';
import { reservedListId } from '../../constants';
import { MealDataFragment } from '../../generated/graphql';
import MealList, { Meal } from './MealList';

type Props = {
  reservedMeals: MealDataFragment[],
  moveMeal: (
    fromListId: DateTime | typeof reservedListId,
    toListId: DateTime | typeof reservedListId,
    movedMeal: Meal
  ) => void,
};

const ReservedMealList = ({ reservedMeals, moveMeal }: Props) => (
  <MealList
    header='vorgemerkt'
    listId={reservedListId}
    meals={reservedMeals}
    moveMeal={(
      toListId: DateTime | typeof reservedListId,
      movedMeal: Meal,
    ) => moveMeal(reservedListId, toListId, movedMeal)}
  />
);

export default ReservedMealList;
