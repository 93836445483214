import { Link } from 'react-router-dom';
import { Button, Header, List } from 'semantic-ui-react';
import { MealDataFragment } from '../../generated/graphql';
import RecipeThumbnail from '../RecipeThumbnail';
import DeleteMealModal from './DeleteMealModal';
import { reservedListId } from '../../constants';
import { DateTime } from 'luxon';
import UpdateMealButton from '../UpdateMealButton';

export type Meal = {
  id: number,
  recipeId?: number,
  note?: string,
};

type Props = {
  header: string,
  listId: string,
  meals: MealDataFragment[],
  moveMeal: (toListId: DateTime | typeof reservedListId, movedMeal: Meal) => void,
};

const MealList = ({ header, listId, meals, moveMeal }: Props) => {
  return (
    <>
      <Header dividing>
        {header}
      </Header>
      <List divided selection>
        {meals.map((meal, i) => (
          <List.Item
            key={i}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <List.Content
              as={meal.recipe ? Link : 'div'} to={meal.recipe ? `/recipe/${meal.recipe.id}` : ''}
              style={{ display: 'flex', alignItems: 'center', flexGrow: '1' }}
            >
              <RecipeThumbnail recipe={meal.recipe || undefined} />
              <div style={{ paddingLeft: '0.5rem' }}>
                {!!meal.recipe?.title &&
                  <div style={{ paddingBottom: '0.5rem' }}>
                    {meal.recipe?.title}
                  </div>}
                {meal.note &&
                  <div>
                    {meal.note}
                  </div>}
              </div>
            </List.Content>
            <List.Content
              style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
            >
              <UpdateMealButton
                updateMeal={(toListId: DateTime | typeof reservedListId) => moveMeal(
                  toListId,
                  {
                    id: meal.id,
                    recipeId: meal.recipe?.id,
                    note: meal.note || undefined,
                  }
                )}
                icon='calendar alternate outline'
                title='umplanen'
                showReserveMealOption={listId !== reservedListId}
              />
              <DeleteMealModal
                id={meal.id}
                infoText={meal?.recipe?.title || meal.note || undefined}
                listId={listId}
                listName={header}
                trigger={<Button
                  basic
                  compact
                  color='red'
                  icon='delete'
                  size='small'
                />}
              />
            </List.Content>
          </List.Item>
        ))}
      </List>
    </>
  );
};

export default MealList;
