import { ReactElement } from 'react';
import { Message, Modal } from 'semantic-ui-react';
import { reservedListId } from '../../constants';
import { useRemoveMealMutation } from '../../generated/graphql';

interface Props {
  id: number,
  infoText?: string,
  listName: string,
  listId: string,
  trigger: ReactElement,
}

const DeleteMealModal = ({ id, infoText: title, listName, listId, trigger }: Props) => {
  const [removeMeal, { error }] = useRemoveMealMutation({
    onError: (error) => {
      console.log(error);
    },
    update: (cache, { data }) => {
      if (data?.removeMeal) {
        cache.modify({
          fields: {
            mealPlan(existingMealPlan = []) {
              if (!existingMealPlan) {
                return;
              }
              const mealRef = `Meal:${id}`;
              if (listId === reservedListId) {
                return {
                  __typename: 'MealPlan',
                  plannedMeals: existingMealPlan.plannedMeals,
                  reservedMeals: existingMealPlan.reservedMeals
                    .filter((meal: { __ref: string; }) => meal.__ref !== mealRef)
                };
              }
              const plannedMealsToUpdate = existingMealPlan.plannedMeals
                .find((meals: { dateString: string; }) => meals.dateString === listId);
              if (!plannedMealsToUpdate) {
                return existingMealPlan;
              }
              return {
                __typename: 'MealPlan',
                plannedMeals: [
                  ...existingMealPlan.plannedMeals
                    .filter((meals: { dateString: string; }) => meals.dateString !== listId),
                  {
                    __typename: 'PlannedMeals',
                    dateString: plannedMealsToUpdate.dateString,
                    meals: plannedMealsToUpdate.meals
                      .filter((meal: { __ref: string; }) => meal.__ref !== mealRef)
                  }
                ],
                reservedMeals: existingMealPlan.reservedMeals,
              };
            }
          }
        },
        );
      }
    }
  });

  const deleteMeal = () => {
    removeMeal({
      variables: {
        input: {
          id,
        }
      }
    });
  };

  if (error) {
    return (<Message error
      header='Da ist etwas schiefgelaufen!'
      content={error.message} />);
  }

  return (<Modal
    trigger={trigger}
    header='Mahlzeit löschen'
    content={`Soll das Rezept "${title}" von der Liste "${listName}" entfernt werden?`}
    actions={[
      'Abbrechen',
      { key: 'delete', content: 'Löschen', negative: true, onClick: deleteMeal }
    ]}
  />);
};

export default DeleteMealModal;
