import { DateTime } from 'luxon';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { reservedListId } from '../constants';
import MealPlanDatepicker from './MealPlanDatepicker';

type Props = {
  updateMeal: (toListId: DateTime | typeof reservedListId) => void,
  icon: string,
  title: string,
  showReserveMealOption?: boolean,
};

const UpdateMealButton = ({ updateMeal, icon, title, showReserveMealOption }: Props) => {
  const [dropdownOpen, setDropdownOpen] = React.useState<boolean | undefined>(undefined);
  React.useEffect(() => {
    if (dropdownOpen !== undefined) {
      setDropdownOpen(undefined);
    }
  }, [dropdownOpen]);

  return (
    <Dropdown
      button
      compact
      open={dropdownOpen}
      className='icon'
      icon={icon}
      title={title}
      direction='left'
    >
      <Dropdown.Menu>
        <Dropdown.Header
          content={title}
        />
        {showReserveMealOption &&
          <Dropdown.Item
            text='vormerken'
            onClick={() => {
              setDropdownOpen(false);
              updateMeal(reservedListId);
            }}
          />
        }
        <Dropdown.Item
          onClick={(e) => e.stopPropagation()}
        >
          <MealPlanDatepicker
            handleSelectedDate={(date: DateTime) => updateMeal(date)}
            setOnChangePreconditions={() => setDropdownOpen(false)}
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UpdateMealButton;
