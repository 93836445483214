import { MealDataFragmentDoc, MealDataFragment, useUpdateMealPlanMutation } from './generated/graphql';

export function useUpdateMealPlanMutationWithCacheUpdate() {
  return useUpdateMealPlanMutation({
    onError: (error) => {
      console.log(error);
    },
    update: (cache, { data }) => {
      if (!data?.updateMealPlan) {
        return;
      }
      const toMealDataCacheFragment = (meal: MealDataFragment) => cache.writeFragment({
        data: meal,
        fragment: MealDataFragmentDoc,
        fragmentName: 'MealData'
      });
      cache.modify({
        fields: {
          mealPlan(existingMealPlan) {
            if (!existingMealPlan) {
              return;
            }
            const updatedPlannedMealDays = data.updateMealPlan.plannedMeals
              .map(plannedMeals => plannedMeals.dateString);

            const newPlannedMeals = existingMealPlan.plannedMeals
              .filter((plannedMeal: { dateString: string; }) =>
                !updatedPlannedMealDays.includes(plannedMeal.dateString))
              .concat(data.updateMealPlan.plannedMeals
                .map(plannedMeals => ({
                  __typename: 'PlannedMeals',
                  dateString: plannedMeals.dateString,
                  meals: plannedMeals.meals
                    .map(toMealDataCacheFragment)
                })));
            const updatedReservedMeals = data.updateMealPlan.reservedMeals
              ?.map(toMealDataCacheFragment);
            return {
              __typename: 'MealPlan',
              plannedMeals: newPlannedMeals,
              reservedMeals: updatedReservedMeals ? updatedReservedMeals : existingMealPlan.reservedMeals,
            };
          }
        }
      });
    }
  });
}
