import React from 'react';
import { Button } from 'semantic-ui-react';

interface YieldsButtonsProps {
  originalYields: number,
  yieldUnit: string,
  yieldsFactor: number,
  setYieldsFactor: (factor: number) => void,
}

const YieldsButtons = ({ originalYields, yieldUnit, yieldsFactor, setYieldsFactor }: YieldsButtonsProps) => {
  const [currentYields, setCurrentYields] = React.useState<number>(originalYields);

  React.useEffect(() => {
    setYieldsFactor(currentYields / originalYields);
  }, [originalYields, setYieldsFactor, currentYields]);

  const increaseYields = () => {
    if (currentYields < 1) {
      setCurrentYields((currentYields + 0.25));
    } else if (currentYields < 1000) {
      setCurrentYields(currentYields + 1);
    } else {
      setCurrentYields(1000);
    }
  };

  const decreaseYields = () => {
    if (currentYields > 1) {
      setCurrentYields(currentYields - 1);
    } else if (currentYields > 0.25) {
      setCurrentYields((currentYields - 0.25));
    } else {
      setCurrentYields(0);
    }
  };

  const resetYields = () => {
    setCurrentYields(originalYields);
  };

  const yields = originalYields * yieldsFactor;

  return (
    <Button.Group>
      <Button icon='minus' onClick={decreaseYields} />
      <Button color='blue' onClick={resetYields} >
        {Number.isInteger(yields) ? yields : yields.toFixed(2)} {yieldUnit}
      </Button>
      <Button icon='plus' onClick={increaseYields} />
    </Button.Group>
  );
};

export default YieldsButtons;
