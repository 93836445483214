import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Dimmer, Loader, Message } from 'semantic-ui-react';
import RecipeView from '../components/recipeView/RecipeView';
import { useRecipeQuery } from '../generated/graphql';

interface LocationState {
  imageHash?: string,
}

interface Props {
  isLoggedIn: boolean;
}

const Recipe = ({ isLoggedIn }: Props) => {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  });

  const locationState = useLocation().state as LocationState;
  const params = useParams();

  const { data, error, loading } = useRecipeQuery({
    variables: {
      id: parseFloat(params.id || ''),
    }
  });

  if (loading) {
    return (
      <Dimmer inverted active>
        <Loader />
      </Dimmer>
    );
  }

  if (error || !data) {
    return (<Message error
      header='Da ist etwas schiefgelaufen!'
      content={error?.message} />);
  }

  document.title = `${data.recipe.title} - RezeptDB`;

  return (<RecipeView
    recipe={data.recipe}
    imageHash={(!!locationState && !!locationState.imageHash) ? locationState.imageHash : ''}
    isLoggedIn={isLoggedIn}
  />);
};

export default Recipe;
