import 'fomantic-ui-css/semantic.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, ApolloLink, ApolloProvider, from, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import './index.css';
import App, { AUTH_TOKEN } from './App';
import reportWebVitals from './reportWebVitals';

const link = createUploadLink({
  uri: `${process.env.REACT_APP_REZEPTDB_API_BASE_URL}/graphql`
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'Authorization': authToken ? `Bearer ${authToken}` : ''
    }
  }));

  return forward(operation);
});

const client = new ApolloClient({
  link: from([
    authMiddleware,
    link
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Recipe: {
        fields: {
          image: {
            merge: true,
          },
        }
      },
    }
  }),
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
