import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FieldInputProps } from 'formik';
import { Ref } from 'semantic-ui-react';
import IngredientRow from './IngredientRow';

interface Props {
  id: string,
  isDisabled: boolean,
  isDragging: boolean,
  formik: {
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>,
  },
  addIngredient: (groudIdx: number, ingIdx: number) => () => void,
}

const SortableIngredientRow = ({ id, isDisabled, isDragging, formik, addIngredient }: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: id, disabled: isDisabled });

  return (
    <Ref innerRef={setNodeRef}>
      <IngredientRow
        style={{
          transform: CSS.Transform.toString(transform),
          opacity: isDragging && 0.5,
          transition,
        }}
        {...attributes}
        itemId={id}
        formik={formik}
        addIngredient={addIngredient}
        dragHandleProps={{
          ...listeners,
          disabled: isDisabled
        }}
      />
    </Ref>
  );
};

export default SortableIngredientRow;
