import { FieldInputProps } from 'formik';
import React from 'react';
import { Form, Grid, Image, Label } from 'semantic-ui-react';
import RecipeImage from './RecipeImage';
import RecipeTags from '../RecipeTags';

interface Props {
  title: FieldInputProps<string>,
  yields: FieldInputProps<number>,
  yieldUnit: FieldInputProps<string>,
  prepTime: FieldInputProps<number>,
  cookTime: FieldInputProps<number>,
  absoluteImageUrl?: string,
  setImage: (image: File) => void,
  tags: string[],
  setTags: (tags: string[]) => void,
}

const RecipeHead = ({ title, yields, yieldUnit, prepTime, cookTime, absoluteImageUrl, setImage, tags, setTags }: Props) => {
  const [imagePreview, setImagePreview] = React.useState(absoluteImageUrl || '/assets/placeholder.jpeg');
  const [isImagePlaceholder, setIsImagePlaceholder] = React.useState(!absoluteImageUrl);

  return (
    <>
      <Grid.Column mobile={16} tablet={8} computer={8}>
        <Grid>
          <Grid.Row style={{ paddingBottom: '0' }}>
            <Grid.Column>
              <Form.Input
                {...title}
                required
                placeholder='Rezeptname'
                autoFocus
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns='2'>
            <Grid.Column mobile={5} tablet={5} computer={4}>
              <Form.Input
                {...yields}
                required
                type='number'
              />
            </Grid.Column>

            <Grid.Column mobile={11} tablet={11} computer={12} style={{ paddingLeft: '0' }}>
              <datalist id='yieldUnitList'>
                <option value='Portion'>Portion</option>
                <option value='Portionen'>Portionen</option>
              </datalist>
              <Form.Input
                {...yieldUnit}
                required
                list='yieldUnitList'
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <RecipeTags tags={tags} setTags={setTags} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={2}>
            <Grid.Column>
              <TimeInput label='Vorbereitungszeit' fieldInput={prepTime} />
            </Grid.Column>
            <Grid.Column>
              <TimeInput label='Zubereitungszeit' fieldInput={cookTime} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <RecipeImage
                setImage={setImage}
                setImagePreview={setImagePreview}
                setIsImagePlaceholder={setIsImagePlaceholder}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column mobile={16} tablet={8} computer={8}>
        <Image
          src={imagePreview}
          rounded
          label={isImagePlaceholder && { color: 'red', content: 'Kein Rezeptbild', ribbon: true }}
        />
      </Grid.Column>
    </>
  );
};

interface TimeInputProps {
  label: string,
  fieldInput: FieldInputProps<number>,
}

const TimeInput = ({ label, fieldInput }: TimeInputProps) => (
  <Form.Input
    {...fieldInput}
    label={label}
    labelPosition='right'
    type='number'
    fluid
  >
    <input />
    <Label basic>Minuten</Label>
  </Form.Input>
);

export default RecipeHead;
