import { DateTime } from 'luxon';
import { Dimmer, Loader, Message } from 'semantic-ui-react';
import MealPlanView from '../components/mealPlan/MealPlanView';
import { useMealPlanQuery } from '../generated/graphql';
import { amountOfFutureMealPlanDays } from '../constants';

const MealPlan = () => {

  document.title = 'Planung - RezeptDB';

  const { data, error, loading } = useMealPlanQuery({
    variables: {
      from: DateTime.now().toISODate(),
      to: DateTime.now().plus({ days: amountOfFutureMealPlanDays }).toISODate(),
    }
  });

  if (loading) {
    return (
      <Dimmer inverted active>
        <Loader />
      </Dimmer>
    );
  }

  if (error || !data) {
    return (<Message error
      header='Da ist etwas schiefgelaufen!'
      content={error?.message}
    />);
  }
  return <MealPlanView mealPlan={data.mealPlan} />;
};

export default MealPlan;
