import { useParams } from 'react-router-dom';
import { Dimmer, Header, Loader, Message } from 'semantic-ui-react';
import RecipeList from '../components/RecipeList';
import { OrderByDirection, OrderByField, useRecipesQuery } from '../generated/graphql';

const RecipesByTag = () => {
  const params = useParams();
  const tagTitle = params.title || '';
  document.title = `${tagTitle} - RezeptDB`;
  const { loading, error, data } = useRecipesQuery({
    variables: {
      orderBy: {
        field: OrderByField.Title,
        direction: OrderByDirection.Asc
      },
      filter: {
        tags: [tagTitle],
      }
    }
  });

  if (loading) {
    return (
      <Dimmer inverted active>
        <Loader />
      </Dimmer>
    );
  }

  if (error || !data) {
    return <div>ERROR: {error?.message}</div>;
  }
  if (data.recipes.length < 1) {
    return (
      <Message warning>
        Zu diesem Rezept-Tag existieren keine Rezepte.
      </Message>
    );
  }

  return (
    <>
      <Header>
        Rezepte mit Tag "{tagTitle}"
      </Header>
      <RecipeList data={data} />
    </>
  );
};

export default RecipesByTag;
