import { DateTime } from 'luxon';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

type Props = {
  handleSelectedDate: (date: DateTime) => void,
  setOnChangePreconditions?: () => void,
};

const getSelectedDate = (value: Date | Date[]): Date => Array.isArray(value) ? value[0] : value;

const MealPlanDatepicker = ({ handleSelectedDate, setOnChangePreconditions }: Props) => {

  return (
    <SemanticDatepicker
      inline
      datePickerOnly
      locale='de-DE'
      minDate={DateTime.now().minus({ days: 1 }).toJSDate()}
      firstDayOfWeek={1}
      filterDate={(date?: Date) => true} // TODO add filter to disable listId day
      showOutsideDays
      onChange={(_, data) => {
        setOnChangePreconditions && setOnChangePreconditions();
        const dateInput = data.value;
        if (!dateInput) {
          return;
        }
        return handleSelectedDate(DateTime.fromJSDate(getSelectedDate(dateInput)));
      }}
    />
  );
};

export default MealPlanDatepicker;
