export const toTimeString = (seconds: number) => {
  const asDate = new Date(seconds * 1000);
  return [getHours(asDate), getMinutes(asDate)]
    .filter(el => el != null)
    .join(' und ');
};

const getHours = (date: Date) => {
  const hours = date.getUTCHours() + (date.getUTCDate() - 1) * 24;
  if (!hours) {
    return null;
  }
  if (hours === 1) {
    return '1 Stunde';
  }
  return `${hours} Stunden`;
};

const getMinutes = (date: Date) => {
  const minutes = date.getUTCMinutes();
  if (!minutes) {
    return null;
  }
  if (minutes === 1) {
    return '1 Minute';
  }
  return `${minutes} Minuten`;
};