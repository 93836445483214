import React from 'react';

export function useMarkableColor(isMarked: boolean) {
  const [color, setColor] = React.useState('#ffffff');
  React.useLayoutEffect(() => {
    if (isMarked) {
      setColor('#cce2ff');
    } else {
      setColor('#ffffff');
    }
  }, [isMarked]);

  return color;
}
