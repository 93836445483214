import { Dimmer, Loader, Message } from 'semantic-ui-react';
import AddRecipe from '../AddRecipe';
import { IngredientGroup } from '../recipeInputForm/ingredientGroupHelper';
import { defaultIngredient, defaultIngredientGroup } from '../recipeInputForm/Ingredients';
import { ImportedRecipe, ImportPayload } from '../../generated/graphql';
import { ApolloError } from '@apollo/client';

type Props = {
  called: boolean,
  error?: ApolloError,
  data?: ImportPayload,
};

const ImportRecipe = ({ called, error, data }: Props) => {

  if (error) {
    return (<Message error
      header='Da ist etwas schiefgelaufen!'
      content={error.message} />);
  }

  if (!!called && !!data && data.recipe) {
    const recipe = data.recipe;
    return (
      <AddRecipe
        recipe={{
          title: recipe.title,
          yields: recipe.yields,
          yieldUnit: recipe.yieldUnit,
          prepTime: !!recipe.prepTime ? Number(recipe.prepTime) / 60 : undefined,
          cookTime: !!recipe.cookTime ? Number(recipe.cookTime) / 60 : undefined,
          ingredientGroups: createIngredients(recipe),
          instructions: recipe.instructions || undefined,
          notes: recipe.notes || undefined,
          tags: [],
        }}
      />
    );
  }

  return (
    <Dimmer inverted active>
      <Loader />
    </Dimmer>
  );

};

const createIngredients = (recipe: ImportedRecipe): IngredientGroup[] => {
  if (!recipe.importedGroupedIngredients || recipe.importedGroupedIngredients.length === 0) {
    return [{
      ingredients: [
        defaultIngredient
      ]
    },
      defaultIngredientGroup
    ];
  }

  const ingredientGroups = recipe.importedGroupedIngredients?.map(group => {
    const ingredients = group.importedIngredients.map(ingredient => ({
      title: ingredient.title,
      unit: ingredient.unit || '',
      amount: ingredient.amount?.toString() || '',
    }));
    return {
      title: group.title || undefined,
      ingredients: [
        ...ingredients,
        defaultIngredient
      ]
    } as IngredientGroup;
  });

  if (ingredientGroups.find(group => !group.title)) {
    return [
      ...ingredientGroups,
      defaultIngredientGroup
    ];
  }

  return [
    defaultIngredientGroup,
    ...ingredientGroups
  ];

};


export default ImportRecipe;
